import React from "react";
import styled, { useTheme } from "styled-components";
import Box from "./Box";
import Button from "./Button";
import QuickTask from "./QuickTask";
import { Spacer } from "./styles/Spacer.styled";
import { Text16 } from "./styles/Typography.styled";

const QuickFilesAndProxyWrapper = styled.div`
  height: 100%;
  grid-column: 4/5;
  grid-row: 1/3;

  .scroller {
    margin-right: -0.5rem;
    padding-right: 0.375rem;
    max-height: 735px;
    overflow-y: auto;
    @media (max-width: 768px) {
      max-height: 500px;
    }
  }
`;

export default function QuickFilesAndProxy() {
  const theme = useTheme();
  return (
    <QuickFilesAndProxyWrapper>
      <Box sm>
        <Text16 weight={500} color={theme.colors.bnw.black3}>
          Quick task files and proxy
        </Text16>
        <Spacer bottom={"1rem"} />
        <div className="scroller">
          {new Array(5)
            .fill({
              days: 1,
              proxy: "USA Proxy Server",
            })
            .map((e, i) => (
              <React.Fragment key={i}>
                <QuickTask {...e} />
                <Spacer bottom={"0.5rem"} />
              </React.Fragment>
            ))}
          <Button text={"Load more"} />
        </div>
      </Box>
    </QuickFilesAndProxyWrapper>
  );
}

import styled from "styled-components";

export const DataVolumeWrapper = styled.div`
  .data-volume {
    position: relative;
    width: 7.5rem;
    aspect-ratio: 1/1;
    border-radius: 0.75rem;
    border: 0.0625rem solid #e6eefd;
    box-shadow: 0rem 0.125rem 0.25rem rgba(0, 0, 0, 0.0212249);
    border-radius: 0.75rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    transition: all 0.2s ease-in-out;
    cursor: pointer;
    margin-right: 1.25rem;

    &:hover {
      box-shadow: 0rem 0.125rem 0.25rem rgba(0, 0, 0, 0.1);
    }

    &.active {
      background: ${({ theme }) => theme.colors.semantic.info};
    }

    .check {
      position: absolute;
      top: 0.5rem;
      right: 0.5rem;
      width: 1.25rem;
      height: 1.25rem;
      border-radius: 50%;
      overflow: hidden;
      width: 1.125rem;
      height: 1.125rem;
    }
  }
`;

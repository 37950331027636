import styled from "styled-components";

export const DiscordBtnWrapper = styled.div`
  background: #ffffff;
  border-radius: 0.5625rem;
  margin: 0 2rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-top: auto;
  padding: 0.75rem 0;
  justify-content: center;
  transition: all 0.3s ease-in-out;

  &:hover {
    background: #f4f6fc;
    transform: scale(1.01);
    box-shadow: 0rem 0.375rem 0.375rem rgba(0, 0, 0, 0.25);
  }

  span {
    font-weight: 700;
    font-size: 0.875rem;
    line-height: 1.25rem;
    color: #1b61ee;
    opacity: 0.98;
    margin-left: 0.5rem;
  }
`;

import React from "react";
import { BoxWrapper } from "./styles/Box.styled";

export default function Box({ children, sm, ...props }) {
  return (
    <BoxWrapper sm={sm} {...props}>
      {children}
    </BoxWrapper>
  );
}

import React from "react";
import styled from "styled-components";
import { MenuIcon } from "../constants";
import { EqualColumns } from "../pages/styles/EqualColumns.styled";
import Box from "./Box";
import Button from "./Button";
import Menu from "./Menu";
import ProxyMenuOptions from "./ProxyMenuOptions";
import { RowBetween } from "./styles/Layout.styled";
import { Spacer } from "./styles/Spacer.styled";
import { Text14, Text18 } from "./styles/Typography.styled";

const ProxiesListWrapper = styled(Box)`
  .scroll {
    padding: 1.25rem;
    max-height: ${(props) => (!props.showMenu ? "19.5rem" : "27rem")};
    overflow-y: auto;
    background: #f4f6fc;
    border-radius: 0.625rem;
  }
`;

export default function ProxiesList({ data = [], showMenu }) {
  return (
    <ProxiesListWrapper showMenu={showMenu}>
      <RowBetween>
        <Text18 weight={800}>Proxies</Text18>
        {showMenu ? (
          <div>
            <Menu
              btn={
                <span
                  style={{
                    width: "1.5rem",
                    height: "1.5rem",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <MenuIcon />
                </span>
              }
            >
              <ProxyMenuOptions />
            </Menu>
          </div>
        ) : (
          <></>
        )}
      </RowBetween>
      <Spacer bottom={"1.5rem"} />
      <div className="scroll">
        {data.map((e, i) => (
          <div key={i}>
            <Text14 weight={500} key={i} color={"#031828"}>
              {e}
            </Text14>
            {i !== 14 && <Spacer bottom={"0.75rem"} />}
          </div>
        ))}
      </div>
      <Spacer bottom={"1.5rem"} />
      <EqualColumns columns={2}>
        <Button
          text={"Copy"}
          onClick={() => window.navigator.clipboard.writeText(data.join("\n"))}
        />
        <Button onClick={null} text={showMenu ? "Download" : "Save"} blue />
      </EqualColumns>
    </ProxiesListWrapper>
  );
}

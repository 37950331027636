import styled from "styled-components";

export const StyledSideTopBar = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem 0;
  width: 100%;

  .logoContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 2.875rem;
    .logo {
      width: 5rem;
      object-fit: contain;
    }
  }

  .manage {
    font-weight: 400;
    font-size: 0.625rem;
    line-height: 0.875rem;
    letter-spacing: 0.0625rem;
    text-transform: uppercase;
    color: #ffffff;
    opacity: 0.78;
    cursor: pointer;
    margin: 0 1.25rem;
    margin-bottom: 1.25rem;
    padding: 0.125rem 0.25rem;
    padding-top: 0.25rem;
    border-radius: 0.25rem;
    width: max-content;
    transition: all 0.3s ease-in-out;

    &:hover {
      background-color: rgba(255, 255, 255, 0.4);
      box-shadow: 0.125rem 0.125rem 0.125rem rgba(0, 0, 0, 0.25);
    }
  }
`;

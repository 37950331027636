import { motion, AnimatePresence } from "framer-motion";
import React, { useState } from "react";
import { useRecoilState } from "recoil";
import { DownArrow } from "../constants";
import { RowBetween } from "./styles/Layout.styled";
import { SelectWrapper } from "./styles/SelectWrapper.styled";

export default function Select({
  placeholder,
  lg,
  values,
  state,
  border = false,
  ...props
}) {
  const [currentValue, setCurrentValue] = useRecoilState(state);
  const [open, setOpen] = useState(false);

  return (
    <SelectWrapper lg={lg} border={border}>
      <RowBetween
        className="select"
        style={{
          cursor: "pointer",
          zIndex: open ? 1000 : 0,
          position: "relative",
        }}
        onClick={() => setOpen(!open)}
      >
        <span className={currentValue ? "currentValue" : "placeholder"}>
          {currentValue ? currentValue : placeholder}
        </span>
        <DownArrow />
      </RowBetween>
      <AnimatePresence mode="wait">
        {open && (
          <>
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.2 }}
              className="overlay"
              onClick={() => setOpen(false)}
            />
            <motion.div
              initial={{ opacity: 0, y: 10 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: 10 }}
              transition={{ duration: 0.2 }}
              className="values"
            >
              <div className="valuesWrapper">
                {values.map((value, index) => {
                  return (
                    <div
                      key={index}
                      className={
                        value === currentValue ? "value active" : "value"
                      }
                      onClick={() => {
                        setCurrentValue(value);
                        setOpen(false);
                      }}
                      {...props}
                    >
                      {value}
                    </div>
                  );
                })}
              </div>
            </motion.div>
          </>
        )}
      </AnimatePresence>
    </SelectWrapper>
  );
}

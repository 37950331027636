import React from "react";
import { StyledSideTopBar } from "./styles/SidebarTopBar.styled";
import Logo from "../assets/logo.png";
import {
  DashIcon,
  ProxyIcon,
  ResiIcon,
  SettingIcon,
  ShopIcon,
  StatementIcon,
} from "../constants";
import NavLinkComponent from "./NavLink";

const links = [
  { text: "Dashboard", icon: DashIcon, path: "/dashboard" },
  {
    text: "Residential Generation",
    icon: ResiIcon,
    path: "/residential",
  },
  {
    text: "Proxies",
    icon: ProxyIcon,
    path: "/proxies",
  },
  {
    text: "Shop",
    icon: ShopIcon,
    path: "/shop",
  },
  {
    text: "Statement",
    icon: StatementIcon,
    path: "/statement",
  },
  {
    text: "Settings",
    icon: SettingIcon,
    path: "/settings",
  },
];

export default function SidebarTopBar() {
  return (
    <StyledSideTopBar>
      <div className="logoContainer">
        <img src={Logo} alt="logo" className="logo" />
      </div>
      <div className="manage">Manage</div>
      {links.map((link) => (
        <NavLinkComponent
          key={link.text}
          text={link.text}
          icon={link.icon}
          path={link.path}
        />
      ))}
    </StyledSideTopBar>
  );
}

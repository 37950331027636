import styled from "styled-components";

export const DatePickerButtonWrapper = styled.div`
  padding: 0.75rem;
  background: #ffffff;
  border: 0.0625rem solid #e6edf2;
  border-radius: 0.5rem;
  cursor: pointer;
  position: relative;

  @media (max-width: 1100px) {
    padding: 0.5rem;
  }

  @media (max-width: 418px) {
    margin-left: 1rem;
    margin-bottom: 0.5rem;
  }
`;

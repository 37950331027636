import styled from "styled-components";

export const ShowLevelsWrapper = styled.div`
  grid-column: 3/4;
  grid-row: 1/3;

  .levelScroll {
    max-height: 33.25rem;
    overflow-y: auto;
    margin-right: -0.5rem;
    padding-right: 0.5rem;
    overflow-x: hidden;
  }
`;

export const LevelsWrapper = styled.div`
  margin-bottom: 0.875rem;

  &:last-child {
    margin-bottom: 0.25rem;
  }

  &:hover {
    box-shadow: 0rem 0.125rem 0.25rem rgb(0, 0, 0, 0.1);
  }

  background-color: ${({ theme }) => theme.colors.bg.style1};
  padding: 0.875rem;
  border-radius: 0.5rem;

  .icon {
    border-radius: 50%;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .feats {
    cursor: pointer;
    position: relative;
    width: max-content;
  }

  .arrow {
    height: 0.5rem;
    width: 0.5rem;
    background-color: #fff;
    border-radius: 0.125rem;
    position: absolute;
    left: 80%;
    top: 50%;
    z-index: 1;
    transform: translateX(0.1875rem) translateY(-50%) rotate(-45deg);
  }

  @media (max-width: 1100px) {
    .arrow {
      left: 85%;
    }
  }
`;

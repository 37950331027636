import styled from "styled-components";

export const InputWrapper = styled.div`
  width: 100%;

  input {
    margin-top: 0.625rem;
    width: 100%;
    background: #f4faff;
    border: 0.0625rem solid #e6edf2;
    border-radius: 0.75rem;
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.5rem;
    padding: 1rem;
    transition: all 0.2s ease-in-out;

    &::placeholder {
      color: #a0a5ba;
    }

    &:focus {
      outline: none;
      box-shadow: inset 0 0 0 0.125rem rgba(0, 123, 255, 0.25);
    }
  }
`;

import React from "react";
import { useTheme } from "styled-components";
import Box from "./Box";
import Level from "./Level";
import MembershipType from "./MembershipType";
import { ShowLevelsWrapper } from "./styles/Levels.styled";
import { Spacer } from "./styles/Spacer.styled";
import { H2, Text14 } from "./styles/Typography.styled";

export default function Levels() {
  const theme = useTheme();
  return (
    <ShowLevelsWrapper>
      <MembershipType type={"Friends"} />
      <Spacer bottom="1.5rem" />
      <Box sm>
        <Text14 weight={500} color={theme.colors.bnw.black3}>
          Your level
        </Text14>
        <Spacer bottom="1rem" />
        <H2 color={theme.colors.semantic.info}>Level 1</H2>
        <Spacer bottom="1.25rem" />
        <Text14 weight={500} color={theme.colors.bnw.black3}>
          All levels
        </Text14>
        <Spacer bottom="1rem" />
        <div className="levelScroll">
          <Level
            levelName={"Level 1"}
            currentPoints={100}
            totalPoints={100}
            feats={["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"]}
            subText={"Curabitur nunc fringilla egestas"}
          />
          <Level
            levelName={"Level 2"}
            currentPoints={50}
            totalPoints={100}
            feats={["1", "2", "3"]}
            subText={"Curabitur nunc fringilla egestas"}
          />
          <Level
            levelName={"Level 3"}
            currentPoints={0}
            totalPoints={100}
            feats={["1", "2", "3"]}
            subText={"Curabitur nunc fringilla egestas"}
          />
          <Level
            levelName={"Level 4"}
            currentPoints={0}
            totalPoints={100}
            feats={["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"]}
            subText={"Curabitur nunc fringilla egestas"}
          />
        </div>
      </Box>
    </ShowLevelsWrapper>
  );
}

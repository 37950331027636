import React from "react";
import { DiscordIcon } from "../constants";
import { DiscordBtnWrapper } from "./styles/DiscordBtnWrapper.styled";

export default function DiscordBtn() {
  return (
    <DiscordBtnWrapper>
      <DiscordIcon />
      <span>Join our Discord</span>
    </DiscordBtnWrapper>
  );
}

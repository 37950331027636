import styled from "styled-components";

export const StatementWrapper = styled.div`
  display: grid;
  grid-template-columns: 16rem 1fr 18rem;
  grid-gap: 1.875rem;

  @media (max-width: 1100px) {
    display: block;
  }
`;

import styled from "styled-components";

export const StyledUser = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  background: #ffffff;
  box-shadow: 0rem 0.25rem 0.25rem rgba(227, 230, 236, 0.25);
  border-radius: 1.5rem;
  padding: 0.1875rem;
  padding-right: 0.625rem;
  cursor: pointer;
  transition: all 0.3s ease-in-out;

  &:hover {
    background-color: rgba(255, 255, 255, 0.8);
    box-shadow: 0rem 0.75rem 0.75rem rgba(227, 230, 236, 0.75);
  }

  .userImg {
    margin-right: 0.5rem;
    background: linear-gradient(
      180deg,
      #e7faff 0%,
      rgba(255, 255, 255, 0) 100%
    );
    border: 0.0625rem solid #f1f1f5;
    width: 2.0625rem;
    height: 2.0625rem;
    border-radius: 50%;
  }
`;

import React from "react";
import styled from "styled-components";
import { FamilySvg } from "../constants";
import { RowCenter } from "./styles/Layout.styled";
import { Spacer } from "./styles/Spacer.styled";
import { H3, Text14 } from "./styles/Typography.styled";

export default function MembershipType({ type }) {
  return (
    <MemberShipWrapper>
      <Text14 weight={500} color={"#fff"}>
        Membership type
      </Text14>
      <Spacer top="1rem" />
      <RowCenter>
        <RowCenter className="icon">
          <FamilySvg />
        </RowCenter>
        <H3 color={"#fff"}>{type}</H3>
      </RowCenter>
    </MemberShipWrapper>
  );
}

const MemberShipWrapper = styled.div`
  padding: 1rem;
  height: max-content;
  background-color: ${({ theme }) => theme.colors.semantic.info};
  box-shadow: 0rem 0.25rem 0.5rem rgba(27, 97, 238, 0.16);
  border-radius: 0.75rem;

  .icon {
    margin-right: 0.75rem;
    padding: 0.5rem;
    border-radius: 50%;
    background: rgba(255, 255, 255, 0.1);
    height: 2rem;
    width: 2rem;
  }
`;

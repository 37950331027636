import React from "react";
import { RowBetween } from "./styles/Layout.styled";
import Logo from "../assets/logo.png";
import { MenuNavWrapper } from "./styles/MenuNav.styled";
import { useRecoilState } from "recoil";
import { NavOpen } from "../recoil/general";

export default function MenuNav() {
  const [isOpen, setIsOpen] = useRecoilState(NavOpen);

  return (
    <MenuNavWrapper>
      <RowBetween>
        <div className="logoContainer">
          <img src={Logo} alt="logo" className="logo" />
        </div>
        <button
          onClick={() => setIsOpen(!isOpen)}
          class={`hamburger hamburger hamburger--collapse ${
            isOpen ? "is-active" : ""
          }`}
          type="button"
        >
          <span class="hamburger-box">
            <span class="hamburger-inner"></span>
          </span>
        </button>
      </RowBetween>
    </MenuNavWrapper>
  );
}

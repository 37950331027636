import styled from "styled-components";

export const BoxWrapper = styled.div`
  background: ${({ theme }) => theme.colors.white};
  padding: ${({ sm }) => (sm ? "1.25rem" : "1.5rem")};
  box-shadow: 0rem 0.125rem 0.25rem rgba(0, 0, 0, 0.0212249);
  border-radius: 0.75rem;
  transition: all 0.2s ease-in-out;

  &:hover {
    box-shadow: 0rem 0.125rem 0.25rem rgba(0, 0, 0, 0.1);
  }

  @media (max-width: 1100px) {
    margin: 1rem 0;
  }
`;

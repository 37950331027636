import React from "react";
import Switch from "../components/Switch";
import Topbar from "../components/Topbar";
import { resiSwitchState } from "../recoil/tabs";
import { StyledPage } from "./styles/PageStyle.styled";
import { Spacer } from "../components/styles/Spacer.styled";
import { EqualColumns } from "./styles/EqualColumns.styled";
import DataBalance from "../components/DataBalance";
import GenerateProxies from "../components/GenerateProxies";
import ProxiesList from "../components/ProxiesList";

export default function Residential() {
  return (
    <StyledPage>
      <Topbar pageName={"Residential Generation"} />
      <Switch values={["Premium", "Basic"]} state={resiSwitchState} />
      <Spacer bottom="2.25rem" />
      <EqualColumns columns={3}>
        <DataBalance />
        <GenerateProxies />
        <ProxiesList
          showMenu
          data={new Array(15).fill("mock.speedy.so:9999:Username:Password:1")}
        />
      </EqualColumns>
    </StyledPage>
  );
}

import styled from "styled-components";

export const Row = styled.div`
  display: flex;
  flex-direction: row;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

// extends row

export const RowCenter = styled(Row)`
  align-items: center;
  flex-wrap: ${(props) => props.wrap || "nowrap"};
  justify-content: ${(props) => props.justify || "flex-start"};
`;

export const RowBetween = styled(Row)`
  flex-direction: ${(props) => props.direction || "row"};
  justify-content: space-between;
  align-items: ${(props) => props.align || "center"};
  height: ${({ direction }) => (direction === "column" ? "100%" : "auto")};
`;

// extends column

export const ColumnCenter = styled(Column)`
  align-items: center;
`;

export const ColumnBetween = styled(Column)`
  justify-content: space-between;
`;

import React from "react";
import { useSetRecoilState } from "recoil";
import { useTheme } from "styled-components";
import { CalendarIcon } from "../constants";
import { datePickerOpen } from "../recoil/general";
import DatePicker from "./DatePicker";
import { DatePickerButtonWrapper } from "./styles/DatePickerButton.styled";
import { RowCenter } from "./styles/Layout.styled";
import { Spacer } from "./styles/Spacer.styled";
import { Text14 } from "./styles/Typography.styled";

export default function DatePickerButton() {
  const theme = useTheme();
  const setPickerOpen = useSetRecoilState(datePickerOpen);

  return (
    <DatePickerButtonWrapper onClick={() => setPickerOpen(true)}>
      <RowCenter>
        <Text14 weight={700} color={theme.colors.bnw.black3}>
          Date & Time
        </Text14>
        <Spacer right={"0.5rem"} />
        <CalendarIcon />
      </RowCenter>

      <DatePicker />
    </DatePickerButtonWrapper>
  );
}

import React from "react";
import { ButtonWrapper } from "./styles/Button.styled";

export default function Button({ blue, text, ...props }) {
  return (
    <ButtonWrapper blue={blue} {...props}>
      {text}
    </ButtonWrapper>
  );
}

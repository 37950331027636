import styled from "styled-components";

export const LargeText = styled.div`
  font-size: 3rem;
  line-height: 3rem;
  color: ${({ color }) => color};
`;

export const H1 = styled.h1`
  font-size: 2.25rem;
  line-height: 2.5rem;
  color: ${({ color }) => color};
`;

export const H2 = styled.h2`
  font-size: 1.875rem;
  line-height: 2.25rem;
  color: ${({ color }) => color};
`;

export const H3 = styled.h3`
  font-size: 1.5rem;
  line-height: 1.875rem;
  font-weight: ${({ weight }) => weight};
  color: ${({ color }) => color};
`;

export const Caption = styled.div`
  font-size: 0.875rem;
  line-height: 1.25rem;
  color: ${({ color }) => color};
`;

export const Text20 = styled.div`
  font-size: 1.25rem;
  line-height: 1.75rem;
  color: ${({ color }) => color};
  font-weight: ${({ weight }) => weight};
`;

export const Text18 = styled.div`
  font-size: 1.125rem;
  line-height: 1.75rem;
  color: ${({ color }) => color};
  font-weight: ${({ weight }) => weight};
`;

export const Text16 = styled.div`
  font-size: 1rem;
  line-height: 1.5rem;
  color: ${({ color }) => color};
  font-weight: ${({ weight }) => weight};
`;

export const Text14 = styled.div`
  font-size: 0.875rem;
  line-height: 1.25rem;
  color: ${({ color }) => color};
  font-weight: ${({ weight }) => weight};
`;

export const Text12 = styled.div`
  font-size: 0.75rem;
  line-height: 1rem;
  color: ${({ color }) => color};
  letter-spacing: -0.005em;
  font-weight: ${({ weight }) => weight};
`;

export const TextHeader34 = styled.div`
  font-size: 2.125rem;
  letter-spacing: -0.02em;
  line-height: 2.5rem;
  color: ${({ color }) => color};
  font-weight: 700;
`;

export const TextHeader20 = styled.div`
  font-size: 1.25rem;
  letter-spacing: -0.02em;
  line-height: 1.4375rem;
  color: ${({ color }) => color};
  font-weight: 700;
`;

import React from "react";
import styled from "styled-components";
import { usageServerState } from "../recoil/select";
import { usageSwitchState } from "../recoil/tabs";
import Box from "./Box";
import DatePickerButton from "./DatePickerButton";
import Select from "./Select";
import StackedColumnChart from "./StackedColumnChart";
import { RowBetween, RowCenter } from "./styles/Layout.styled";
import { Spacer } from "./styles/Spacer.styled";
import { Text18 } from "./styles/Typography.styled";
import Switch from "./Switch";

const UsageWrapper = styled.div`
  grid-column: 1/4;
  grid-row: 2/3;
`;

export default function Usage() {
  return (
    <UsageWrapper>
      <Box>
        <RowBetween>
          <div>
            <Text18 weight={700}>Usage Overview</Text18>
            <Spacer bottom="0.5rem" />
            <Select
              values={[
                "All Servers",
                "footlocker.de",
                "footlocker.fr",
                "Solect.io",
                "loremipsum.de",
              ]}
              state={usageServerState}
            />
          </div>

          <RowCenter wrap={"wrap"} justify={"flex-end"}>
            <DatePickerButton />
            <Spacer right={"0.75rem"} />
            <Switch values={["Premium", "Basic"]} state={usageSwitchState} />
          </RowCenter>
        </RowBetween>
        <StackedColumnChart />
      </Box>
    </UsageWrapper>
  );
}

import React from "react";
import { useNavigate } from "react-router-dom";
import { useTheme } from "styled-components";
import { CheckSvg } from "../constants";
import Box from "./Box";
import Button from "./Button";
import { RowCenter } from "./styles/Layout.styled";
import { PlanComponentWrapper } from "./styles/PlanComponentWrapper.styled";
import { Spacer } from "./styles/Spacer.styled";
import { Text16 } from "./styles/Typography.styled";

export default function PlanComponent({
  name,
  price,
  time,
  imgSrc,
  feats = [],
}) {
  const navigate = useNavigate();
  const theme = useTheme();
  return (
    <Box>
      <PlanComponentWrapper>
        <div>
          <RowCenter>
            <img src={imgSrc} alt="plan" />
            <div>
              <Text16 weight={500} color={theme.colors.bnw.black2}>
                {name}
              </Text16>
              <div>
                <span className="price">{price}</span>
                <span className="time">{time}</span>
              </div>
            </div>
          </RowCenter>
          <Spacer bottom="1.25rem" />
          <Text16 color={theme.colors.bnw.black4}>
            Lorem ipsum dolor sit amet consectetur, adipisicing elit.
          </Text16>
          <div className="border" />
          <div>
            {feats.map((e, i) => (
              <div key={i} className="feat">
                <Text16 weight={500} color={theme.colors.bnw.black2}>
                  {e.name}
                </Text16>
                <CheckSvg checked={e.checked} />
              </div>
            ))}
          </div>
          <Spacer top="2rem" />
        </div>
        <Button 
          onClick={() => navigate("/shop/detail")}
        text={"Choose plan"} />
      </PlanComponentWrapper>
    </Box>
  );
}

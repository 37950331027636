import React from "react";
import { DataBalanceWrapper } from "./styles/DataBalance.styled";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

export default function DataBalanceGraph({
  color = "#1B61EE",
  height = "15rem",
  children,
}) {
  const value = 1;

  return (
    <DataBalanceWrapper>
      <CircularProgressbar
        value={value}
        maxValue={10}
        strokeWidth={10}
        styles={{
          // Customize the root svg element
          root: {
            backgroundColor: "transparent",
            height: height,
            width: height,
            border: "0.625rem solid #E6EEFD",
            borderRadius: "50%",
          },
          // Customize the path, i.e. the "completed progress"
          path: {
            // Path color
            stroke: color,
            // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
            strokeLinecap: "butt",
            // Customize transition animation
            transition: "stroke-dashoffset 0.5s ease 0s",
            // Rotate the path
            transformOrigin: "center center",
          },
          // Customize the circle behind the path, i.e. the "total progress"
          trail: {
            // Trail color
            stroke: "#d6d6d6",
            // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
            strokeLinecap: "butt",
            // Rotate the trail
            transform: "rotate(0.25turn)",
            transformOrigin: "center center",
          },
          // Customize the text
          text: {
            // Text color
            fill: "#f88",
            // Text size
            fontSize: "16px",
          },
          // Customize background - only used when the `background` prop is true
          background: {
            fill: "#3e98c7",
          },
        }}
      />
      {children}
    </DataBalanceWrapper>
  );
}

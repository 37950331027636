import React from "react";
import styled from "styled-components";
import {
  settingsInput1State,
  settingsInput2State,
  settingsInput3State,
  settingsInput4State,
  settingsInput5State,
} from "../recoil/dataInput";
import Box from "./Box";
import Button from "./Button";
import Input from "./Input";
import { Spacer } from "./styles/Spacer.styled";
import { Text18 } from "./styles/Typography.styled";

export default function SettingComponent() {
  return (
    <Box>
      <Text18 weight={800}>Settings</Text18>
      <Spacer bottom={"1.5rem"} />
      <Input
        type="text"
        label={"Settings 1"}
        state={settingsInput1State}
        placeholder="Enter Settings 1"
      />
      <Spacer bottom={"1.5rem"} />
      <Input
        type="text"
        label={"Settings 2"}
        state={settingsInput2State}
        placeholder="Enter Settings 2"
      />
      <Spacer bottom={"1.5rem"} />
      <Input
        type="text"
        label={"Settings 3"}
        state={settingsInput3State}
        placeholder="Enter Settings 3"
      />
      <Spacer bottom={"1.5rem"} />
      <Input
        type="text"
        label={"Settings 4"}
        state={settingsInput4State}
        placeholder="Enter Settings 4"
      />
      <Spacer bottom={"1.5rem"} />
      <Input
        type="text"
        label={"Settings 5"}
        state={settingsInput5State}
        placeholder="Enter Settings 5"
      />
      <Spacer bottom={"2rem"} />
      <Button blue text={"Generate"} />
    </Box>
  );
}

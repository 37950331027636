import styled from "styled-components";

export const SwitchWrapper = styled.div`
  display: flex;
  align-items: center;
  background: #e8ebf4;
  border-radius: 0.5rem;
  width: max-content;
  font-weight: 700;
  font-size: 0.875rem;
  line-height: 1.25rem;
  color: #606e79;
  padding: 0.25rem 0.125rem;

  .value {
    padding: 0.5rem 0.75rem;
    cursor: pointer;
    margin: 0 0.125rem;
    text-align: center;
    transition: all 0.2s ease-in-out;

    &:hover {
      background: #ffffff;
      box-shadow: 0rem 0.25rem 0.25rem rgba(68, 68, 79, 0.1);
      border-radius: 0.375rem;
    }
  }

  .active {
    background: #ffffff;
    box-shadow: 0rem 0.25rem 0.25rem rgba(68, 68, 79, 0.1);
    border-radius: 0.375rem;
    cursor: pointer;
    padding: 0.5rem 0.75rem;
    text-align: center;
    color: #1b61ee;
    margin: 0 0.125rem;
  }
`;

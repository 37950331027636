import React from "react";
import { useRecoilState } from "recoil";
import { SwitchWrapper } from "./styles/Switch.styled";

export default function Switch({ values, state }) {
  const [currentValue, setCurrentValue] = useRecoilState(state);

  return (
    <SwitchWrapper>
      {values.map((value, index) => {
        return (
          <div
            key={index}
            className={value === currentValue ? "active" : "value"}
            onClick={() => setCurrentValue(value)}
          >
            {value}
          </div>
        );
      })}
    </SwitchWrapper>
  );
}

import { atom } from "recoil";

export const proxyCountState = atom({
  key: "proxyCountState",
  default: 1,
});

export const paymentMethodState = atom({
  key: "paymentMethodState",
  default: "card",
});

export const userMenuState = atom({
  key: "userMenuState",
  default: false,
});

export const NavOpen = atom({
  key: "NavOpen",
  default: false,
});

export const datePickerOpen = atom({
  key: "datePickerOpen",
  default: false,
});

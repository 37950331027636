import React from "react";
import styled, { useTheme } from "styled-components";
import Box from "./Box";
import { Text14, Text16 } from "./styles/Typography.styled";
import { Spacer } from "./styles/Spacer.styled";
import Select from "./Select";
import { usageTypeState } from "../recoil/select";
import { RowBetween } from "./styles/Layout.styled";
import CustomProgress from "./CustomProgress";

export default function CurrentUsage() {
  const theme = useTheme();

  return (
    <Box sm>
      <RowBetween>
        <Text16 color={theme.colors.bnw.black3}>Current usage</Text16>
        <div
          style={{
            width: "100%",
            maxWidth: "6.25rem",
          }}
        >
          <Select border values={["Basic", "Premium"]} state={usageTypeState} />
        </div>
      </RowBetween>
      <Spacer bottom="1.25rem" />
      <CustomProgress
        dot
        bgColor={theme.colors.primary.aqua}
        height={"1.25rem"}
        radius={"0.75rem"}
        color={theme.colors.primary.blue}
        currentPoints={30}
        totalPoints={35}
      />
      <Spacer bottom="1rem" />
      <UsageSection
        type={"Used"}
        value={"30 GB"}
        fill={theme.colors.primary.blue}
      />
      <Spacer bottom="0.25rem" />
      <UsageSection
        type={"Remaining"}
        value={"10 GB"}
        fill={theme.colors.primary.aqua}
      />
    </Box>
  );
}

const UsageWrappper = styled.div`
  display: flex;
  border: 0.0625rem solid #e6edf2;
  border-radius: 0.375rem;
  width: max-content;
  align-items: center;
  padding: 0.25rem 0.5rem;

  .rect {
    width: 0.75rem;
    height: 0.75rem;
    border: 0.0625rem solid #ffffff;
    background-color: ${(props) => props.fill};
    box-shadow: 0rem 0.25rem 0.25rem rgba(163, 175, 200, 0.24);
    border-radius: 0.25rem;
  }
`;

export const UsageSection = ({ fill, type, value }) => {
  return (
    <UsageWrappper fill={fill}>
      <div className="rect" />
      <Spacer left="0.625rem" />
      <Text14
        color="#031828"
        style={{
          opacity: 0.5,
        }}
      >
        {type}
      </Text14>
      <Spacer right="0.5rem" />
      <Text14 weight={700} color="#031828">
        {value}
      </Text14>
    </UsageWrappper>
  );
};

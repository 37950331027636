import React from "react";
import { useTheme } from "styled-components";
import Box from "./Box";
import { RowBetween } from "./styles/Layout.styled";
import { Spacer } from "./styles/Spacer.styled";
import { H1, Text14, Text16 } from "./styles/Typography.styled";

export default function YourLevel() {
  const theme = useTheme();

  return (
    <Box sm>
      <RowBetween direction="column" align="flex-start">
        <div>
          <Text16 color={theme.colors.bnw.black3}>Your Level</Text16>
        </div>
        <Spacer bottom="1.25rem" />
        <H1>Level 1</H1>
        <Spacer bottom="1rem" />
        <Text14 color={"#636C7F"}>Know more about Speedy Level systems.</Text14>
      </RowBetween>
    </Box>
  );
}

import React from "react";
import { useTheme } from "styled-components";
import { GridWrapper } from "./styles/PointsHistoryGrid.styled";
import { Text14 } from "./styles/Typography.styled";

const data = [
  {
    id: 54547,
    time: "22 May, 2021",
    product: "2GB Premium Residential",
    expiry: "22 May, 2021",
    amount: "€40",
    payment: "Visa Card",
    action: "Download",
  },
  {
    id: 54548,
    time: "22 May, 2021",
    product: "2GB Premium Residential",
    expiry: "22 May, 2021",
    amount: "€40",
    payment: "Visa Card",
    action: "Download",
  },
  {
    id: 54549,
    time: "22 May, 2021",
    product: "2GB Premium Residential",
    expiry: "22 May, 2021",
    amount: "€40",
    payment: "Visa Card",
    action: "Download",
  },
  {
    id: 545470,
    time: "22 May, 2021",
    product: "2GB Premium Residential",
    expiry: "22 May, 2021",
    amount: "€40",
    payment: "Visa Card",
    action: "Download",
  },
  {
    id: 54541,
    time: "22 May, 2021",
    product: "2GB Premium Residential",
    expiry: "22 May, 2021",
    amount: "€40",
    payment: "Visa Card",
    action: "Download",
  },
  {
    id: 54513,
    time: "22 May, 2021",
    product: "2GB Premium Residential",
    expiry: "22 May, 2021",
    amount: "€40",
    payment: "Visa Card",
    action: "Download",
  },
  {
    id: 54545,
    time: "22 May, 2021",
    product: "2GB Premium Residential",
    expiry: "22 May, 2021",
    amount: "€40",
    payment: "Visa Card",
    action: "Download",
  },
  {
    id: 55547,
    time: "22 May, 2021",
    product: "2GB Premium Residential",
    expiry: "22 May, 2021",
    amount: "€40",
    payment: "Visa Card",
    action: "Download",
  },
  {
    id: 54517,
    time: "22 May, 2021",
    product: "2GB Premium Residential",
    expiry: "22 May, 2021",
    amount: "€40",
    payment: "Visa Card",
    action: "Download",
  },
  {
    id: 50847,
    time: "22 May, 2021",
    product: "2GB Premium Residential",
    expiry: "22 May, 2021",
    amount: "€40",
    payment: "Visa Card",
    action: "Download",
  },
  {
    id: 64547,
    time: "22 May, 2021",
    product: "2GB Premium Residential",
    expiry: "22 May, 2021",
    amount: "€40",
    payment: "Visa Card",
    action: "Download",
  },
];

export default function InvoiceGrid() {
  const theme = useTheme();
  return (
    <GridWrapper className="invoice-grid">
      <div className="header">
        <Text14 title="Order ID" weight={500} color={theme.colors.bnw.black4}>
          Order ID
        </Text14>
        <Text14
          title="Date & Time"
          weight={500}
          color={theme.colors.bnw.black4}
        >
          Date & Time
        </Text14>
        <Text14 title="Product" weight={500} color={theme.colors.bnw.black4}>
          Product
        </Text14>
        <Text14 title="Amount" weight={500} color={theme.colors.bnw.black4}>
          Amount
        </Text14>
        <Text14
          title="Expiry date"
          weight={500}
          color={theme.colors.bnw.black4}
        >
          Expiry date
        </Text14>
        <Text14 title="Payment" weight={500} color={theme.colors.bnw.black4}>
          Payment
        </Text14>
        <Text14 title="Action" weight={500} color={theme.colors.bnw.black4}>
          Action
        </Text14>
      </div>
      <div className="values">
        {data.map((item) => (
          <div className="row" key={item.id}>
            <Text14
              title={item.id}
              weight={500}
              color={theme.colors.bnw.black2}
            >
              {item.id}
            </Text14>
            <Text14
              title={item.time}
              weight={500}
              color={theme.colors.bnw.black2}
            >
              {item.time}
            </Text14>
            <Text14
              title={item.product}
              weight={600}
              color={theme.colors.bnw.black1}
            >
              {item.product}
            </Text14>
            <Text14
              title={item.amount}
              weight={500}
              color={theme.colors.bnw.black2}
            >
              {item.amount}
            </Text14>
            <Text14
              title={item.expiry}
              weight={500}
              color={theme.colors.bnw.black2}
            >
              {item.expiry}
            </Text14>
            <Text14
              title={item.payment}
              weight={500}
              color={theme.colors.bnw.black2}
            >
              {item.payment}
            </Text14>
            <Text14
              className="action"
              weight={500}
              color={theme.colors.semantic.info}
            >
              {item.action}
            </Text14>
          </div>
        ))}
      </div>
    </GridWrapper>
  );
}

import React from "react";
import { Text18 } from "./styles/Typography.styled";
import Box from "./Box";
import styled, { useTheme } from "styled-components";
import InvoiceGrid from "./InvoiceGrid";
import { Spacer } from "./styles/Spacer.styled";

export default function Invoice() {
  const theme = useTheme();

  return (
    <InvoiceWrapper>
      <Text18 weight={700} color={theme.colors.primary.black}>
        Invoice
      </Text18>
      <Spacer bottom="1rem" />
      <InvoiceGrid />
    </InvoiceWrapper>
  );
}

const InvoiceWrapper = styled(Box)`
  grid-column: 1/3;
`;

import React, { useState } from "react";
import { useTheme } from "styled-components";
import Box from "./Box";
import CustomCheck from "./CustomCheck";
import { RowCenter } from "./styles/Layout.styled";
import { ProxyPlanOrderWrapper } from "./styles/ProxyPlanOrder.styled";
import { Spacer } from "./styles/Spacer.styled";
import { Text12, Text18, TextHeader20 } from "./styles/Typography.styled";

export const plans = [
  {
    id: "#584561",
    name: "Basic Residential",
    lastOrder: new Date(),
  },
  {
    id: "#584562",
    name: "Premium Residential",
    lastOrder: new Date(),
  },
  {
    id: "#584563",
    name: "ISP",
    lastOrder: new Date(),
  },
  {
    id: "#584564",
    name: "Data Center",
    lastOrder: new Date(),
  },
];

export default function ProxyPlanOrder() {
  const [plan, setPlan] = useState(plans[0]);

  return (
    <Box>
      <Text18 weight={700}>Select Order</Text18>
      <Spacer bottom={"1.5rem"} />
      <ProxyPlanOrderWrapper>
        {plans.map((e) => (
          <ProxyPlan
            key={e.id}
            {...e}
            onClick={() => setPlan({ ...e })}
            active={plan.id === e.id}
          />
        ))}
      </ProxyPlanOrderWrapper>
    </Box>
  );
}

function ProxyPlan({ id, name, lastOrder, active, ...props }) {
  const theme = useTheme();

  return (
    <div className={`proxyPlan ${active ? "active" : ""}`} {...props}>
      <RowCenter>
        <CustomCheck checked={active} />
      </RowCenter>
      <div>
        <Text12 weight={700} color={theme.colors.semantic.warning}>
          {id}
        </Text12>
        <Spacer bottom={"0.375rem"} />
        <TextHeader20>{name}</TextHeader20>
        <Spacer bottom={"0.25rem"} />
        <Text12 color="#9B9B9B">
          Ordered on{" "}
          <span
            style={{
              fontWeight: 700,
            }}
          >
            {lastOrder.toLocaleDateString("en-us", {
              day: "2-digit",
              month: "long",
              year: "numeric",
            })}
          </span>
        </Text12>
      </div>
    </div>
  );
}

import React from "react";
import { StyledNavLink } from "./styles/NavLink.styled";
import { NavLink } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import { NavOpen } from "../recoil/general";

export default function NavLinkComponent({ text, icon: Icon, path }) {
  const setMenuOpen = useSetRecoilState(NavOpen);

  return (
    <StyledNavLink onClick={() => setMenuOpen(false)}>
      <NavLink to={path}>
        <Icon />
        <span>{text}</span>
      </NavLink>
    </StyledNavLink>
  );
}

import React from "react";
import styled, { useTheme } from "styled-components";
import Box from "./Box";
import { RowBetween, RowCenter } from "./styles/Layout.styled";
import { Spacer } from "./styles/Spacer.styled";
import { H1, Text14, Text16 } from "./styles/Typography.styled";

const Span = styled.span`
  color: ${(props) => props.color};
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.25rem;
  margin-left: 0.5rem;
  padding: 0.125rem 0.5rem;
  border-radius: 0.8125rem;
  background-color: rgba(0, 201, 184, 0.1);
`;

export default function CurrentPoints() {
  const theme = useTheme();

  return (
    <Box sm>
      <RowBetween direction="column" align="flex-start">
        <div>
          <Text16 color={theme.colors.bnw.black3}>Current points</Text16>
        </div>
        <Spacer bottom="1.25rem" />
        <RowCenter>
          <H1>276.32</H1>
          <Span color="#00b87c">+0.5%</Span>
        </RowCenter>
        <Spacer bottom="1rem" />
        <Text14 color={"#636C7F"}>
          Earn 300 more points to reach the Next Level.
        </Text14>
      </RowBetween>
    </Box>
  );
}

import React from "react";
import MenuNav from "./MenuNav";
import Sidebar from "./Sidebar";
import { StyledMain } from "./styles/Main.styled";
import Wrapper from "./Wrapper";

export default function Main() {
  return (
    <StyledMain>
      <Sidebar />
      <MenuNav />
      <Wrapper />
    </StyledMain>
  );
}

import styled from "styled-components";

export const StyledNavLink = styled.div`
  a {
    padding: 0.75rem 1rem;
    display: flex;
    min-height: 3.125rem;
    align-items: center;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.5rem;
    color: #ffffff;
    border-radius: 2rem 0 0 2rem;
    margin-left: 1.125rem;
    position: relative;

    svg {
      margin-right: 0.75rem;
      width: 1.5rem;
      flex-shrink: 0;
    }
  }

  span {
    position: relative;
    z-index: 4;
    white-space: nowrap;
  }

  .active {
    background-color: ${({ theme }) => theme.colors.bg.style1};
    color: ${({ theme }) => theme.colors.bnw.black1};
    letter-spacing: -0.01em;
    font-weight: 700;

    &::before {
      position: absolute;
      content: "";
      bottom: 100%;
      right: 0;
      width: 3.125rem;
      height: 3.125rem;
      z-index: 2;
      pointer-events: none;
      box-shadow: 0 1.5rem 0 0 ${({ theme }) => theme.colors.bg.style1};
      border-bottom-right-radius: 1.5rem;
    }

    &::after {
      position: absolute;
      content: "";
      top: 100%;
      right: 0;
      width: 3.125rem;
      height: 3.125rem;
      z-index: 2;
      pointer-events: none;
      box-shadow: 0 -1.5rem 0 0 ${({ theme }) => theme.colors.bg.style1};
      border-top-right-radius: 1.5rem;
    }
  }

  a:not(.active):hover {
    transition: all 0.3s ease-in-out;
    &:hover {
      background-color: rgba(255, 255, 255, 0.2);
    }
  }
`;

import styled from "styled-components";

export const ProxyPlanOrderWrapper = styled.div`
  max-height: 25rem;
  margin-right: -0.625rem;
  padding-right: 0.625rem;
  overflow: auto;

  .proxyPlan {
    padding: 1rem;
    border: 0.0625rem solid #e8ebf4;
    border-radius: 1rem;
    margin-bottom: 1rem;
    display: grid;
    grid-template-columns: auto 1fr;
    grid-gap: 1rem;
    transition: all 0.3s ease-in-out;
    cursor: pointer;

    &:last-child {
      margin-bottom: 0;
    }

    &:hover {
      box-shadow: 0rem 0.5rem 1rem rgba(132, 135, 165, 0.16);
    }

    &.active {
      border-color: #aac4f9;
      box-shadow: 0rem 0.5rem 1rem rgba(132, 135, 165, 0.16);
    }
  }
`;

import styled from "styled-components";

export const EqualColumns = styled.div`
  display: grid;
  grid-template-columns: ${({ columns }) => `repeat(${columns}, 1fr)`};
  grid-gap: ${({ gap = "1.875rem" }) => gap};

  &.dashColumn {
    grid-template-rows: auto 1fr;
  }

  @media (max-width: 1100px) {
    display: block;
  }
`;

import styled from "styled-components";

export const MenuWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;

  .btnContainer {
    cursor: pointer;
    background: #fff;
    border-radius: 0.375rem;
    border: 0.0625rem solid rgba(160, 165, 186, 0.2);
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.2s ease-in-out;
  }

  .btnContainer:hover {
    background: #f5f5f5;
  }

  .btnContainer:active {
    background: #e5e5e5;
  }

  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10000;
  }

  .content {
    position: absolute;
    top: 100%;
    margin-top: 0.5rem;
    right: 0;
    height: 100%;
    z-index: 10001;
  }
`;

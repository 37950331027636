import styled from "styled-components";

export const StyledSideBar = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100%;
  overflow-y: auto;
  flex-basis: 16.875rem;
  position: sticky;
  top: 0;
  flex-shrink: 0;
  padding-top: 2rem;
  padding-bottom: 2.5rem;
  height: 100vh;
  background-color: ${(props) => props.theme.colors.primary.darkBlue};

  @media (max-width: 1100px) {
    position: fixed;
    flex-basis: 100%;
    width: 70%;
    z-index: 100;
    max-height: 100vh;
    overflow-y: auto;
    margin-top: auto;
    top: 0;
    left: 0;
    transform: translateX(-100%);
    transition: transform 0.3s ease-in-out;

    @keyframes slideIn {
      0% {
        transform: translateX(-100%);
      }
      100% {
        transform: translateX(0);
      }
    }

    &.open {
      animation: slideIn 0.3s ease-in-out forwards;
    }
  }
`;

import React from "react";
import Invoice from "../components/Invoice";
import ShowLevels from "../components/ShowLevels";
import PointsHistory from "../components/PointsHistory";
import StatementCurrentPoints from "../components/StatementCurrentPoints";
import Topbar from "../components/Topbar";
import { StyledPage } from "./styles/PageStyle.styled";
import { StatementWrapper } from "./styles/Statement.styled";

export default function Statement() {
  return (
    <StyledPage>
      <Topbar pageName={"Statement"} />
      <StatementWrapper>
        <StatementCurrentPoints />
        <PointsHistory />
        <Invoice />
        <ShowLevels />
      </StatementWrapper>
    </StyledPage>
  );
}

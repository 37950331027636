import { atom } from "recoil";

export const proxyNameState = atom({
  key: "proxyNameState",
  default: "",
});

export const couponState = atom({
  key: "couponState",
  default: "",
});

export const dateInputState = atom({
  key: "dateInputState",
  default: new Date(),
});

export const fromTime = atom({
  key: "fromTime",
  default: "00:00",
});

export const toTime = atom({
  key: "toTime",
  default: "23:59",
});

export const settingsInput1State = atom({
  key: "settingsInput1State",
  default: "",
});

export const settingsInput2State = atom({
  key: "settingsInput2State",
  default: "",
});

export const settingsInput3State = atom({
  key: "settingsInput3State",
  default: "",
});

export const settingsInput4State = atom({
  key: "settingsInput4State",
  default: "",
});

export const settingsInput5State = atom({
  key: "settingsInput5State",
  default: "",
});

import React from "react";
import styled, { useTheme } from "styled-components";
import Box from "./Box";
import { Spacer } from "./styles/Spacer.styled";
import { Text18 } from "./styles/Typography.styled";
import { RowBetween, RowCenter } from "./styles/Layout.styled";
import Input from "./Input";
import { couponState } from "../recoil/dataInput";
import Button from "./Button";
import PaymentMethods from "./PaymentMethods";

const OrderSummaryWrapper = styled(Box)`
  .border {
    height: 0.0625rem;
    width: 100%;
    background-color: #e6eefd;
  }

  input {
    min-width: 100%;
    margin-top: 0;
  }

  .apply {
    background: #ebf4ff;
    border-radius: 0.75rem;
    font-weight: 700;
    font-size: 1rem;
    line-height: 1.5rem;
    letter-spacing: -0.01em;
    color: #1b61ee;
    width: 7rem;
    user-select: none;
    margin-left: 1rem;
    padding: 1rem 0;
    text-align: center;
    cursor: pointer;
    transition: all 0.2s ease-in-out;

    &:hover {
      background: #d6e8ff;
    }
  }
`;

export default function OrderSummary() {
  const theme = useTheme();
  return (
    <OrderSummaryWrapper>
      <Text18 weight={700} color={theme.colors.bnw.black3}>
        Order Summary
      </Text18>
      <Spacer bottom="1.25rem" />
      <RowBetween>
        <Text18 weight={500}>Sub-Total</Text18>
        <div
          style={{
            flexBasis: "3rem",
          }}
        >
          <Text18 weight={500} color={theme.colors.black}>
            €40
          </Text18>
        </div>
      </RowBetween>
      <Spacer top="1rem" />
      <RowCenter
        style={{
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <Input placeholder="Coupon" state={couponState} />
        <div className="apply">Apply</div>
      </RowCenter>
      <Spacer bottom="1.25rem" />
      <RowBetween>
        <Text18 weight={600} color={theme.colors.black}>
          Discount
        </Text18>
        <div
          style={{
            flexBasis: "3rem",
          }}
        >
          <Text18 weight={700} color={theme.colors.black}>
            €0
          </Text18>
        </div>
      </RowBetween>
      <Spacer bottom=".125rem" />
      <RowBetween>
        <Text18 weight={700} color={theme.colors.black}>
          Total
        </Text18>
        <div
          style={{
            flexBasis: "3rem",
          }}
        >
          <Text18 weight={700} color={theme.colors.black}>
            €40
          </Text18>
        </div>
      </RowBetween>
      <Spacer bottom="1.25rem" />
      <div className="border" />
      <Spacer bottom="1.5rem" />

      <Text18 weight={700} color={theme.colors.bnw.black3}>
        Payment method
      </Text18>
      <Spacer bottom="1.25rem" />
      <PaymentMethods />
      <Spacer bottom="1.75rem" />
      <Button text={"Proceed"} blue />
    </OrderSummaryWrapper>
  );
}

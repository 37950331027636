import styled from "styled-components";
import Box from "../Box";

export const CurrentPointsWrapper = styled(Box)`
  .points {
    font-weight: 500;
    font-size: 2.5rem;
    line-height: 2.5rem;
    color: #1b61ee;
  }

  .points-sub-component {
    padding: 1rem 0.75rem;
    background-color: ${({ theme }) => theme.colors.bg.style2};
    border-radius: 0.5rem;
  }

  @media (max-width: 1100px) {
    .points-sub-component {
      margin: 0.5rem 0;
    }
  }
`;

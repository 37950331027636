import React from "react";
import { useTheme } from "styled-components";
import { proxyNameState } from "../recoil/dataInput";
import { proxyLocationState, proxyTypeState } from "../recoil/select";
import Box from "./Box";
import Button from "./Button";
import Input from "./Input";
import ProxyCount from "./ProxyCount";
import Select from "./Select";
import { Spacer } from "./styles/Spacer.styled";
import { Text14, Text18 } from "./styles/Typography.styled";

export default function GenerateProxies() {
  const theme = useTheme();

  return (
    <Box>
      <Text18 weight={800}>Generate Proxies</Text18>
      <Spacer bottom={"1.5rem"} />
      <Input
        type="text"
        label={"Proxy name"}
        state={proxyNameState}
        placeholder="Enter proxy name"
      />
      <Spacer bottom={"1.5rem"} />
      <Text14 weight={500} color={theme.colors.bnw.black4}>
        Proxy type
      </Text14>
      <Spacer bottom={".625rem"} />
      <Select
        placeholder={"Select"}
        lg
        values={["Proxy A"]}
        state={proxyTypeState}
      />
      <Spacer bottom={"1.5rem"} />
      <Text14 weight={500} color={theme.colors.bnw.black4}>
        Location
      </Text14>
      <Spacer bottom={".625rem"} />
      <Select
        placeholder={"Select"}
        lg
        values={["US"]}
        state={proxyLocationState}
      />
      <Spacer bottom={"1.5rem"} />
      <Text14 weight={500} color={theme.colors.bnw.black4}>
        No. of Proxies
      </Text14>
      <ProxyCount />
      <Spacer bottom={"2rem"} />
      <Button blue text={"Generate"} />
    </Box>
  );
}

import styled from "styled-components";

export const FeatsPopupWrapper = styled.div`
  position: absolute;
  bottom: 0;
  transform: translate(10px, 0.375rem);
  left: 75%;
  background: rgba(255, 255, 255, 0.85);
  box-shadow: 0rem 0.6875rem 1.5rem rgba(52, 113, 202, 0.310971);
  backdrop-filter: blur(0.375rem);
  width: 10.75rem;
  border-radius: 0.5rem;
  cursor: auto;

  .header {
    background: ${({ color }) => color};
    border-radius: 8px 8px 0px 0px;
    padding: 0.5rem 0.625rem;
  }

  .body {
    padding: 0.625rem;
    max-height: 6rem;
    overflow: auto;
  }

  .icon {
    cursor: pointer;
  }

  .bullet {
    width: 4px;
    height: 4px;
    border-radius: 50%;
    background: #000;
    margin-right: 0.625rem;
  }
`;

import styled from "styled-components";

export const DataBalanceWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin: auto;
  width: 18rem;

  .svgContainer {
    border-radius: 50%;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .border {
      border: 0.0625rem solid #e6eefd;
      padding: 0.25rem;
      border-radius: 50%;
    }

    .svg {
      background: #ffffff;
      box-shadow: 0rem 0rem 0.375rem rgba(93, 138, 231, 0.13);
      width: 3.5rem;
      height: 3.5rem;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
`;

import { RecoilRoot } from "recoil";
import { ThemeProvider } from "styled-components";
import Main from "./components/Main";
import { theme } from "./constants/theme";
import GlobalStyle from "./GlobalStyles";

function App() {
  return (
    <RecoilRoot>
      <ThemeProvider theme={theme}>
        <GlobalStyle />
        <Main />
      </ThemeProvider>
    </RecoilRoot>
  );
}

export default App;

import styled from "styled-components";

export const ProxyCountWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 0.625rem;

  .count {
    padding: 0.875rem;
    background: #f4faff;
    border: 0.0625rem solid #e6edf2;
    border-radius: 0.75rem;
    width: 100%;
  }

  span {
    padding: 0.125rem 0.5rem;
    border-radius: 0.25rem;
    cursor: pointer;

    &:hover {
      background: #e6edf2;
    }
  }

  input {
    background-color: transparent;
    text-align: center;
    font-weight: 500;
    font-size: 1rem;
    color: #a0a5ba;
    line-height: 1.5rem;

    &::placeholder {
      color: #a0a5ba;
    }
  }

  .max {
    background: #ebf4ff;
    border-radius: 0.75rem;
    font-weight: 700;
    font-size: 1rem;
    line-height: 1.5rem;
    letter-spacing: -0.01em;
    color: #1b61ee;
    width: 7rem;
    user-select: none;
    margin-left: 1rem;
    padding: 1rem 0;
    text-align: center;
    cursor: pointer;
    transition: all 0.2s ease-in-out;

    &:hover {
      background: #d6e8ff;
    }
  }
`;

import React from "react";
import styled, { useTheme } from "styled-components";
import Box from "./Box";
import DataBalanceGraph from "./DataBalanceGraph";
import { Spacer } from "./styles/Spacer.styled";
import { H1, H3, Text14, Text16, Text18 } from "./styles/Typography.styled";

const DataBalanceWrapper = styled(Box)``;

export default function Expiration() {
  const theme = useTheme();

  return (
    <DataBalanceWrapper>
      <Text18 weight={800}>Expiration</Text18>
      <Spacer bottom="2rem" />
      <DataBalanceGraph>
        <div className="svgContainer">
          <Text14 color={theme.colors.bnw.black3}>Expire in</Text14>
          <Spacer bottom=".25rem" />
          <H1 weight={800}>99</H1>
          <Spacer bottom=".25rem" />
          <Text14 color={theme.colors.bnw.black3}>Days</Text14>
        </div>
      </DataBalanceGraph>
      <Spacer bottom="1.5rem" />
      <Text16 weight={500} color={theme.colors.bnw.black4}>
        Plan
      </Text16>
      <Spacer bottom="0.25rem" />
      <H3>Premium Residential</H3>
      <Spacer bottom="1rem" />
      <Text16 weight={600} color={theme.colors.bnw.black4}>
        Expire Date
      </Text16>
      <Spacer bottom="0.25rem" />
      <Text16 weight={700} color={"#3A3F4A"}>
        {new Date().toLocaleDateString("en-us", {
          day: "2-digit",
          month: "long",
          year: "numeric",
        })}
      </Text16>
    </DataBalanceWrapper>
  );
}

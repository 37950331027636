import React from "react";
import CurrentUsage from "../components/CurrentUsage";
import CurrentPoints from "../components/CurrentPoints";
import Topbar from "../components/Topbar";
import { EqualColumns } from "./styles/EqualColumns.styled";
import { StyledPage } from "./styles/PageStyle.styled";
import YourLevel from "../components/YourLevel";
import QuickFilesAndProxy from "../components/QuickFilesAndProxy";
import Usage from "../components/Usage";

export default function Dashboard() {
  return (
    <StyledPage>
      <Topbar pageName={"Dashboard"} />
      <EqualColumns className="dashColumn" columns={4}>
        <CurrentUsage />
        <CurrentPoints />
        <YourLevel />
        <QuickFilesAndProxy />
        <Usage />
      </EqualColumns>
    </StyledPage>
  );
}

import styled from "styled-components";

export const CustomProgressWrapper = styled.div`
  background: ${({ bgColor }) => bgColor || "rgba(170, 196, 249, 0.5)"};
  border-radius: ${({ borderRadius = "0.5rem" }) => borderRadius};
  width: 100%;
  height: ${({ height = "0.5rem" }) => height};
  overflow: hidden;
  position: relative;

  .dot {
    max-width: 0.25rem;
    max-height: 0.25rem;
    width: 100%;
    height: 100%;
    transform: none;
    position: absolute;
    border-radius: 50%;
    background: #ffffff;
    opacity: 0.4;
    margin-right: 0.5rem;
  }
`;

import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import Dashboard from "../pages/Dashboard";
import Proxies from "../pages/Proxies";
import Residential from "../pages/Residential";
import Settings from "../pages/Settings";
import Shop from "../pages/Shop";
import ShopDetail from "../pages/ShopDetail";
import Statement from "../pages/Statement";
import { StyledWrapper } from "./styles/Wrapper.styled";

export default function Wrapper() {
  return (
    <StyledWrapper>
      <Routes>
        <Route path="/dashboard" index element={<Dashboard />} />
        <Route path="/residential" element={<Residential />} />
        <Route path="/proxies" element={<Proxies />} />
        <Route path="/shop" element={<Shop />} />
        <Route path="/shop/detail" element={<ShopDetail />} />
        <Route path="/statement" element={<Statement />} />
        <Route path="/settings" element={<Settings />} />
        <Route path="*" element={<Navigate to="/dashboard" replace />} />
      </Routes>
    </StyledWrapper>
  );
}

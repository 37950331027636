import React from "react";
import { useTheme } from "styled-components";
import { DataUsageSvg } from "../constants";
import Box from "./Box";
import { UsageSection } from "./CurrentUsage";
import DataBalanceGraph from "./DataBalanceGraph";
import { Spacer } from "./styles/Spacer.styled";
import { H1, Text16, Text18 } from "./styles/Typography.styled";

export default function DataBalance() {
  const theme = useTheme();

  return (
    <Box>
      <Text18 weight={800}>Data Balance</Text18>
      <Spacer bottom="2rem" />
      <DataBalanceGraph height="18rem">
        <div className="svgContainer">
          <div className="border">
            <div className="svg">
              <DataUsageSvg />
            </div>
          </div>
        </div>
      </DataBalanceGraph>
      <Spacer bottom="2rem" />
      <Text16 weight={500} color={"#606E79"}>
        Used
      </Text16>
      <Spacer bottom="0.25rem" />
      <H1>300 MB</H1>
      <Spacer bottom="1.75rem" />
      <UsageSection type={"Used"} fill={theme.colors.primary.blue} />
      <Spacer bottom="0.25rem" />
      <UsageSection type={"Remaining"} fill={theme.colors.primary.aqua} />
    </Box>
  );
}

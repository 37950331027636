import React, { useState } from "react";
import { useTheme } from "styled-components";
import { DatePickerWrapper } from "./styles/DatePicker.styled";
import { Spacer } from "./styles/Spacer.styled";
import { Text14 } from "./styles/Typography.styled";
import { DayPicker } from "react-day-picker";
import "react-day-picker/dist/style.css";
import { useRecoilState } from "recoil";
import { dateInputState, fromTime, toTime } from "../recoil/dataInput";
import { addMonths, isSameMonth } from "date-fns";
import { RowBetween, RowCenter } from "./styles/Layout.styled";
import { datePickerOpen } from "../recoil/general";
import TimePicker from "./TimePicker";

export default function DatePicker() {
  const theme = useTheme();
  const [selected, setSelected] = useRecoilState(dateInputState);
  const today = new Date();
  const nextMonth = addMonths(new Date(), 0);
  const [month, setMonth] = useState(nextMonth);
  const [isPickerOpen, setIsPickerOpen] = useRecoilState(datePickerOpen);

  const footer = (
    <>
      <Spacer top=".5rem" />
      <button
        className="todayButton"
        disabled={isSameMonth(today, month)}
        onClick={() => setMonth(today)}
      >
        Go to Today
      </button>
    </>
  );

  return (
    <DatePickerWrapper sm open={isPickerOpen}>
      <RowBetween>
        <Text14 weight={500} color={theme.colors.bnw.black3}>
          Date and Time
        </Text14>
        <RowCenter
          justify={"center"}
          className="close"
          onClickCapture={(e) => {
            e.stopPropagation();
            setIsPickerOpen(false);
          }}
        >
          &times;
        </RowCenter>
      </RowBetween>
      <Spacer bottom={"1rem"} />
      <DayPicker
        mode="range"
        captionLayout="dropdown-buttons"
        fromYear={2015}
        toYear={2025}
        month={month}
        onMonthChange={setMonth}
        selected={selected}
        onSelect={setSelected}
        footer={footer}
      />

      <Spacer top={"1rem"} />

      <RowBetween>
        <TimePicker label={"From"} stateAtom={fromTime} />
        <TimePicker label={"To"} stateAtom={toTime} />
      </RowBetween>
    </DatePickerWrapper>
  );
}

import React from "react";
import { useRecoilValue } from "recoil";
import { NavOpen } from "../recoil/general";
import DiscordBtn from "./DiscordBtn";
import SidebarTopBar from "./SidebarTopBar";
import { StyledSideBar } from "./styles/Sidebar.styled";

export default function Sidebar() {
  const isOpen = useRecoilValue(NavOpen);
  return (
    <StyledSideBar className={isOpen ? "open" : ""}>
      <SidebarTopBar />
      <DiscordBtn />
    </StyledSideBar>
  );
}

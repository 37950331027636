import React from "react";
import { CustomProgressWrapper } from "./styles/CustomProgress.styled";
import { motion } from "framer-motion";

export default function CustomProgress({
  color,
  currentPoints,
  totalPoints,
  height,
  radius,
  bgColor,
  dot,
}) {
  return (
    <CustomProgressWrapper
      height={height}
      borderRadius={radius}
      bgColor={bgColor}
    >
      <motion.div
        className="progress"
        initial={{ width: 0 }}
        animate={{ width: (currentPoints / totalPoints) * 100 + "%" }}
        transition={{ duration: 0.75, ease: "easeOut", delay: 0.25 }}
        style={{
          backgroundColor: color,
          position: "absolute",
          borderRadius: radius ? radius : ".25rem",
          top: 0,
          left: 0,
          originX: 0,
          height: "100%",
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
        }}
      >
        {dot && <div className="dot" />}
      </motion.div>
    </CustomProgressWrapper>
  );
}

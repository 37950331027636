import React from "react";
import { useTheme } from "styled-components";
import { EqualColumns } from "../pages/styles/EqualColumns.styled";
import { CurrentPointsWrapper } from "./styles/CurrentPoints.styled";
import { RowCenter } from "./styles/Layout.styled";
import { Spacer } from "./styles/Spacer.styled";
import { Text12, Text16, Text20 } from "./styles/Typography.styled";

export default function StatementCurrentPoints() {
  const theme = useTheme();
  const today = -2500;
  const thisWeek = 5000;
  return (
    <CurrentPointsWrapper>
      <Text16 weight={500} color={theme.colors.bnw.black2}>
        Current points
      </Text16>

      <Spacer top="1.25rem" />

      <div className="points">2500</div>

      <Spacer top="1.5rem" />

      <EqualColumns columns={2} gap="0.5625rem">
        <PointsSubComponent
          title="Today"
          value={today}
          color={
            today < 0 ? theme.colors.semantic.danger : theme.colors.bnw.black1
          }
        />
        <PointsSubComponent
          title="This week"
          value={thisWeek}
          color={
            thisWeek < 0
              ? theme.colors.semantic.danger
              : theme.colors.bnw.black1
          }
        />
      </EqualColumns>

      <Spacer top="0.5625rem" />

      <EqualColumns columns={2} gap="0.5625rem">
        <PointsSubComponent
          title="This month"
          value={today}
          color={
            today < 0 ? theme.colors.semantic.danger : theme.colors.bnw.black1
          }
        />
        <PointsSubComponent
          title="This year"
          value={thisWeek}
          color={
            thisWeek < 0
              ? theme.colors.semantic.danger
              : theme.colors.bnw.black1
          }
        />
      </EqualColumns>
    </CurrentPointsWrapper>
  );
}

const PointsSubComponent = ({ title, value, color }) => {
  return (
    <div className="points-sub-component">
      <Text12 weight={500} color="#606E79">
        {title}
      </Text12>
      <Spacer top="0.75rem" />
      <RowCenter>
        <Text20 weight={600} color={color}>
          {value}
        </Text20>
        <Spacer left="0.125rem" />
        <Text12 color="#606E79" weight={400}>
          pts
        </Text12>
      </RowCenter>
    </div>
  );
};

import { atom } from "recoil";

export const usageServerState = atom({
  key: "usageServerState",
  default: "All Servers",
});

export const usageTypeState = atom({
  key: "usageTypeState",
  default: "Basic",
});

export const proxyTypeState = atom({
  key: "proxyTypeState",
  default: "",
});

export const proxyLocationState = atom({
  key: "proxyLocationState",
  default: "",
});

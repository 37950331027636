import { atom } from "recoil";

export const resiSwitchState = atom({
  key: "resiSwitchState",
  default: "Premium",
});

export const proxySwitchState = atom({
  key: "proxySwitchState",
  default: "Premium",
});

export const shopSwitchState = atom({
  key: "shopSwitchState",
  default: "Residential",
});

export const usageSwitchState = atom({
  key: "usageSwitchState",
  default: "Premium",
});

import React from "react";
import { CloseSvg } from "../constants";
import { FeatsPopupWrapper } from "./styles/FeatsPopup.styled";
import { RowBetween, RowCenter } from "./styles/Layout.styled";
import { Text12 } from "./styles/Typography.styled";

export default function FeatsPopup({ color, feats, level }) {
  return (
    <>
      <div className="arrow"></div>
      <FeatsPopupWrapper color={color}>
        <div className="header">
          <RowBetween>
            <div>
              <Text12 weight={700} color="white">
                Features
              </Text12>
              <Text12 weight={400} color="rgba(255, 255, 255, 0.5)">
                {level}
              </Text12>
            </div>
            <div className="icon">
              <CloseSvg />
            </div>
          </RowBetween>
        </div>
        <div className="body">
          {feats.map((feat, index) => (
            <RowCenter className="feat" key={index}>
              <div className="bullet"></div>
              <Text12 weight={500}>{feat}</Text12>
            </RowCenter>
          ))}
        </div>
      </FeatsPopupWrapper>
    </>
  );
}

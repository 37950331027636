import styled from "styled-components";

export const ButtonWrapper = styled.button`
  background: ${({ blue, theme }) =>
    blue ? theme.colors.semantic.info : theme.colors.white};
  width: 100%;
  border: 0.0625rem solid ${({ blue }) => (!blue ? "#AAC4F9" : "transparent")};
  border-radius: 0.75rem;
  padding: 1rem;
  color: ${({ blue, theme }) =>
    blue ? theme.colors.white : theme.colors.semantic.info};
  font-weight: 700;
  font-size: 1rem;
  line-height: 1.5rem;
  transition: all 0.2s ease-in-out;

  &:hover {
    background: ${({ blue, theme }) =>
      !blue ? theme.colors.semantic.info : theme.colors.white};
    border-color: ${({ blue }) => (blue ? "#AAC4F9" : "transparent")};
    color: ${({ blue, theme }) =>
      !blue ? theme.colors.white : theme.colors.semantic.info};
  }

  @media (max-width: 1100px) {
    margin: 0.25rem 0;
  }
`;

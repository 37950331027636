import styled from "styled-components";

export const TimePickerWrapper = styled.div`
  .input {
    background: #ffffff;
    border: 0.0625rem solid #e6eefd;
    border-radius: 0.375rem;
    padding: 0.375rem 0.75rem;
    overflow: hidden;

    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.25rem;
    letter-spacing: 0.0313rem;
    text-transform: uppercase;
  }

  input {
    background-color: transparent;
    color: #374151;
    white-space: nowrap;
  }
`;

import React from "react";
import { useTheme } from "styled-components";
import PointsHistoryGrid from "./PointsHistoryGrid";
import { PointsHistoryWrapper } from "./styles/PointsHistory.styled";
import { Spacer } from "./styles/Spacer.styled";
import { Text16 } from "./styles/Typography.styled";

export default function PointsHistory() {
  const theme = useTheme();
  return (
    <PointsHistoryWrapper>
      <Text16 weight={500} color={theme.colors.bnw.black2}>
        Points History
      </Text16>
      <Spacer bottom="1rem" />
      <PointsHistoryGrid />
    </PointsHistoryWrapper>
  );
}

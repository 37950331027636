import styled from "styled-components";

export const TopBarStyled = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 1.5625rem;

  .pagename {
    font-weight: 700;
    font-size: 1.5rem;
    line-height: 2.25rem;
    letter-spacing: 0.0063rem;
    color: ${({ theme }) => theme.colors.bnw.black1};
  }
`;

import img1 from "../assets/ShopPlan1.png";
import img2 from "../assets/ShopPlan2.png";

export const ResiShopData = [
  {
    name: "Premium",
    imgSrc: img1,
    price: "€40",
    time: "/m",
    feats: [
      { name: "Id turpis aliquam vitae.", checked: true },
      { name: "Id turpis aliquam vitae.", checked: true },
      { name: "Id turpis aliquam vitae.", checked: true },
      { name: "Id turpis aliquam vitae.", checked: true },
      { name: "Id turpis aliquam vitae.", checked: true },
      { name: "Id turpis aliquam vitae.", checked: true },
      { name: "Id turpis aliquam vitae.", checked: false },
    ],
  },
  {
    name: "Basic",
    imgSrc: img2,
    price: "€30",
    time: "/m",
    feats: [
      { name: "Id turpis aliquam vitae.", checked: true },
      { name: "Id turpis aliquam vitae.", checked: true },
      { name: "Id turpis aliquam vitae.", checked: true },
      { name: "Id turpis aliquam vitae.", checked: false },
      { name: "Id turpis aliquam vitae.", checked: true },
      { name: "Id turpis aliquam vitae.", checked: true },
      { name: "Id turpis aliquam vitae.", checked: true },
    ],
  },
];

export const WorkShopData = [
  {
    name: "Data Center",
    imgSrc: img1,
    price: "€30",
    time: "/m",
    feats: [
      { name: "Id turpis aliquam vitae.", checked: true },
      { name: "Id turpis aliquam vitae.", checked: true },
      { name: "Id turpis aliquam vitae.", checked: true },
      { name: "Id turpis aliquam vitae.", checked: true },
      { name: "Id turpis aliquam vitae.", checked: true },
      { name: "Id turpis aliquam vitae.", checked: true },
      { name: "Id turpis aliquam vitae.", checked: false },
    ],
  },
  {
    name: "ISP",
    imgSrc: img1,
    price: "€30",
    time: "/m",
    feats: [
      { name: "Id turpis aliquam vitae.", checked: true },
      { name: "Id turpis aliquam vitae.", checked: true },
      { name: "Id turpis aliquam vitae.", checked: true },
      { name: "Id turpis aliquam vitae.", checked: true },
      { name: "Id turpis aliquam vitae.", checked: true },
      { name: "Id turpis aliquam vitae.", checked: true },
      { name: "Id turpis aliquam vitae.", checked: false },
    ],
  },
  {
    name: "Release",
    imgSrc: img1,
    price: "€30",
    time: "/m",
    feats: [
      { name: "Id turpis aliquam vitae.", checked: true },
      { name: "Id turpis aliquam vitae.", checked: true },
      { name: "Id turpis aliquam vitae.", checked: true },
      { name: "Id turpis aliquam vitae.", checked: true },
      { name: "Id turpis aliquam vitae.", checked: true },
      { name: "Id turpis aliquam vitae.", checked: true },
      { name: "Id turpis aliquam vitae.", checked: false },
    ],
  },
  {
    name: "Captcha",
    imgSrc: img1,
    price: "€30",
    time: "/m",
    feats: [
      { name: "Id turpis aliquam vitae.", checked: true },
      { name: "Id turpis aliquam vitae.", checked: true },
      { name: "Id turpis aliquam vitae.", checked: true },
      { name: "Id turpis aliquam vitae.", checked: true },
      { name: "Id turpis aliquam vitae.", checked: true },
      { name: "Id turpis aliquam vitae.", checked: true },
      { name: "Id turpis aliquam vitae.", checked: false },
    ],
  },
];

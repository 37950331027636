import React from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { DownArrow } from "../constants";
import { userMenuState } from "../recoil/general";
import { userDataState } from "../recoil/userData";
import { StyledUser } from "./styles/User.styled";
import UserMenu from "./UserMenu";

export default function User() {
  const userData = useRecoilValue(userDataState);
  const [showMenu, setShowMenu] = useRecoilState(userMenuState);

  return (
    <StyledUser onClick={() => setShowMenu(!showMenu)}>
      <img src={userData.img} className="userImg" alt="user-img" />
      <DownArrow />
      {showMenu && <UserMenu userData={userData} />}
    </StyledUser>
  );
}

import React from "react";
import styled, { useTheme } from "styled-components";
import Box from "./Box";
import { Spacer } from "./styles/Spacer.styled";
import { H2, Text16, Text18 } from "./styles/Typography.styled";
import shopPlan1 from "../assets/ShopPlan1.png";
import DataVolume from "./DataVolume";

const PackageDetailsWrapper = styled(Box)`
  .border {
    height: 0.0625rem;
    width: 100%;
    background-color: #e6eefd;
  }
`;

export default function PackageDetails() {
  const theme = useTheme();
  return (
    <PackageDetailsWrapper>
      <Text18 weight={700} color={theme.colors.bnw.black3}>
        Package details
      </Text18>
      <Spacer bottom="1.25rem" />
      <img src={shopPlan1} alt="img1" />
      <Spacer bottom="1rem" />
      <H2 weight={700} color={"031828"}>
        Premium Residential
      </H2>
      <Spacer bottom="0.5rem" />
      <Text16 weight={500} color={theme.colors.bnw.black3}>
        45 days validity, Real IPs and more
      </Text16>
      <Spacer bottom="2rem" />
      <div className="border" />
      <Spacer bottom="1.5rem" />
      <DataVolume data={[2, 5, 10]} />
    </PackageDetailsWrapper>
  );
}

import { atom } from "recoil";

export const userDataState = atom({
  key: "userDataState",
  default: {
    name: "John Doe",
    img: "https://w0.peakpx.com/wallpaper/208/752/HD-wallpaper-whatsapp-dp-cartoon.jpg",
    id: "123456789",
  },
});

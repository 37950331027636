import React from "react";
import { useRecoilState } from "recoil";
import { ArrowLeft, ArrowRight } from "../constants";
import { proxyCountState } from "../recoil/general";
import { RowBetween } from "./styles/Layout.styled";
import { ProxyCountWrapper } from "./styles/ProxyCount.styled";
import Joi from "joi";

export default function ProxyCount() {
  const [proxyCount, setProxyCount] = useRecoilState(proxyCountState);
  const schema = Joi.object({
    proxyCount: Joi.number()
      .integer()
      .positive()
      .precision(0)
      .min(1)
      .max(100)
      .required(),
  });

  return (
    <ProxyCountWrapper>
      <RowBetween className="count">
        <span
          onClick={() => {
            if (proxyCount > 1) {
              setProxyCount(proxyCount - 1);
            }
          }}
        >
          <ArrowLeft />
        </span>
        <input
          placeholder="Enter proxy count"
          type="number"
          value={proxyCount}
          onChange={(e) => {
            const { error } = schema.validate({ proxyCount: e.target.value });
            if (!error || e.target.value === "") {
              setProxyCount(e.target.value);
            }
          }}
        />
        <span
          onClick={() => {
            if (proxyCount < 100) {
              setProxyCount(parseInt(proxyCount || 1) + 1);
            }
          }}
        >
          <ArrowRight />
        </span>
      </RowBetween>

      <div
        className="max"
        onClick={() => {
          setProxyCount(100);
        }}
      >
        Max
      </div>
    </ProxyCountWrapper>
  );
}

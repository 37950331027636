import React from "react";
import { AnimatePresence, motion } from "framer-motion";
import { MenuWrapper } from "./styles/Menu.styled";

export default function Menu({ btn, children }) {
  const [open, setOpen] = React.useState(false);
  return (
    <MenuWrapper>
      <div className={"btnContainer"} onClick={() => setOpen(!open)}>
        {btn}
      </div>
      <AnimatePresence mode="wait">
        {open && (
          <>
            <div className="overlay" onClick={() => setOpen(false)} />
            <motion.div
              initial={{ opacity: 0, y: 10 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: 10 }}
              className="content"
            >
              {children}
            </motion.div>
          </>
        )}
      </AnimatePresence>
    </MenuWrapper>
  );
}

import React from "react";
import { useRecoilState } from "recoil";
import { useTheme } from "styled-components";
import { Spacer } from "./styles/Spacer.styled";
import { TimePickerWrapper } from "./styles/TimePicker.styled";
import { Text14 } from "./styles/Typography.styled";

export default function TimePicker({ label, stateAtom }) {
  const theme = useTheme();
  const [value, setValue] = useRecoilState(stateAtom);

  return (
    <TimePickerWrapper>
      <Text14 weight={500} color={theme.colors.bnw.black4}>
        {label}
      </Text14>
      <Spacer bottom={"0.5rem"} />
      <div className="input">
        <input
          value={value}
          onChange={(e) => setValue(e.target.value)}
          type="time"
        />
      </div>
    </TimePickerWrapper>
  );
}

import styled from "styled-components";

export const PlanComponentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;

  img {
    width: 4.25rem;
    margin-right: 1.25rem;
  }

  .price {
    font-weight: 700;
    font-size: 1.875rem;
    line-height: 2.25rem;
    color: #165cee;
  }

  .time {
    font-weight: 700;
    font-size: 0.875rem;
    line-height: 1.25rem;
    color: #165cee;
    opacity: 0.5;
  }

  .border {
    width: 100%;
    height: 0.0625rem;
    background: #e6edf2;
    box-shadow: 0rem 0.125rem 0.25rem rgba(0, 0, 0, 0.0212249);
    margin-top: 1.25rem;
    margin-bottom: 1.5rem;
  }

  .feat {
    display: grid;
    grid-template-columns: 1fr 1.125rem;
    grid-gap: 1rem;
    margin-bottom: 0.8125rem;
  }
`;

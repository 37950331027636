import React from "react";
import styled from "styled-components";
import { motion, AnimatePresence } from "framer-motion";

const CustomCheckWrapper = styled.div`
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 50%;
  transition: all 0.2s ease-in-out;
  border: 0.125rem solid
    ${({ theme, checked }) =>
      checked ? theme.colors.semantic.info : theme.colors.bnw.gray2};
  display: flex;
  align-items: center;
  justify-content: center;

  .innerDiv {
    width: 0.875rem;
    height: 0.875rem;
    border-radius: 50%;
    background: ${({ theme }) => theme.colors.semantic.info};
  }
`;

export default function CustomCheck({ checked }) {
  return (
    <CustomCheckWrapper checked={checked}>
      <AnimatePresence>
        {checked && (
          <motion.div
            initial={{ scale: 0 }}
            animate={{ scale: 1 }}
            exit={{ scale: 0 }}
            transition={{ duration: 0.2 }}
            className="innerDiv"
          />
        )}
      </AnimatePresence>
    </CustomCheckWrapper>
  );
}

import React from "react";
import { DashIcon, ShopIcon, SignOutIcon } from "../constants";
import { RowCenter } from "./styles/Layout.styled";
import { Spacer } from "./styles/Spacer.styled";
import { Text12, Text14, Text16 } from "./styles/Typography.styled";
import { UserMenuWrapper } from "./styles/UserMenu.styled";
import { motion } from "framer-motion";

export default function UserMenu({ userData }) {
  return (
    <UserMenuWrapper>
      <div className="overlay" />
      <motion.div
        initial={{ opacity: 0, y: 10 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.2 }}
      >
        <div className="arrow" />
        <div className="content">
          <div className="userDetails">
            <RowCenter>
              <img src={userData?.img} alt="user-img" />
              <Spacer right="0.75rem" />
              <div className="userDetailsText">
                <Text16 weight={500} color="#374151">
                  {userData?.name}
                </Text16>
                <Text12 weight={400} color={"#6B7280"}>
                  ID: {userData?.id}
                </Text12>
              </div>
            </RowCenter>
          </div>
          <div className="sections">
            <RowCenter className="link">
              <ShopIcon fill="#606E79" />
              <Spacer right={"0.75rem"} />
              <Text14 weight={400} color="#3A3F4A">
                Shop
              </Text14>
            </RowCenter>
            <RowCenter className="link">
              <DashIcon fill="#606E79" />
              <Spacer right={"0.75rem"} />
              <Text14 weight={400} color="#3A3F4A">
                Dashboard
              </Text14>
            </RowCenter>
          </div>
          <div className="links">
            <Text14 className="link" weight={400} color="#3A3F4A">
              Speedy
            </Text14>
            <Text14 className="link" weight={400} color="#3A3F4A">
              Imprint
            </Text14>
            <Text14 className="link" weight={400} color="#3A3F4A">
              Privacy Policy
            </Text14>
          </div>
          <RowCenter className="link signOut">
            <SignOutIcon />
            <Spacer right={"0.75rem"} />
            <Text14 weight={500} color="#3A3F4A">
              Sign Out
            </Text14>
          </RowCenter>
        </div>
      </motion.div>
    </UserMenuWrapper>
  );
}

import styled from "styled-components";

export const QuickTaskWrapper = styled.div`
  padding: 0.875rem;
  background: #f7f9fe;
  border-radius: 0.4375rem;
  transition: all 0.3s ease-in-out;

  &:hover {
    background: #f4f6fc;
    box-shadow: 0rem 0.15rem 0.15rem rgba(0, 0, 0, 0.125);
  }

  .downloadBtn {
    width: max-content;
    cursor: pointer;
    border-radius: 0.25rem;
    padding: 0.125rem 0.25rem;
    transition: all 0.3s ease-in-out;

    &:hover {
      background: #fff;
      box-shadow: 0rem 0.15rem 0.15rem rgba(0, 0, 0, 0.125);
    }
  }

  .delete svg {
    border-radius: 50%;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    width: 1.25rem;
    height: 1.25rem;

    &:hover {
      box-shadow: 0rem 0.15rem 0.15rem rgba(0, 0, 0, 0.125);

      circle {
        fill: ${({ theme }) => theme.colors.semantic.danger};
      }

      path {
        fill: #fff;
      }
    }
  }
`;

import styled from "styled-components";
import Box from "../Box";

export const DatePickerWrapper = styled(Box)`
  position: absolute;
  z-index: 10;
  box-shadow: 0rem 0.6875rem 1.5rem rgba(52, 113, 202, 0.310971);
  right: 0;
  top: 2.5rem;
  visibility: ${({ open }) => (open ? "visible" : "hidden")};
  cursor: auto;

  &:hover {
    box-shadow: 0rem 0.6875rem 1.5rem rgba(52, 113, 202, 0.310971);
  }

  option {
    padding: 0.125rem 0.25rem;
  }

  button.todayButton:disabled {
    opacity: 0.5;
  }

  .rdp-dropdown_month {
    cursor: pointer;
    background: #f4f6fc;
    border-radius: 0.5rem;
    padding: 0.25rem;
    color: ${({ theme }) => theme.colors.semantic.info};
    margin-right: 0.875rem;
    font-size: 0.875rem;

    & .rdp-caption_label {
      border: 0px !important;
      background-color: transparent !important;
    }
  }

  .close {
    padding: 0.25rem 0.25rem;
    height: 1.5rem;
    width: 1.5rem;
    border: 0.0625rem solid #aac4f9;
    background-color: #f4f6fc;
    cursor: pointer;
    border-radius: 50%;

    &:hover {
      border-color: ${({ theme }) => theme.colors.semantic.danger};
      background-color: ${({ theme }) => theme.colors.semantic.danger};
      color: #fff;
    }
  }

  & .rdp-dropdown_year {
    font-size: 0.875rem;
    cursor: pointer;

    & .rdp-caption_label {
      border: 0px !important;
      background-color: transparent !important;
    }
  }

  button.todayButton {
    padding: 0.25rem 0.5rem;
    border-radius: 0.25rem;
    border: 0.0625rem solid #aac4f9;
    border-radius: 0.375rem;
    background-color: #fff;
  }

  .rdp-day_range_middle {
    background: #f4faff;
    color: #374151;
  }

  .rdp {
    --rdp-accent-color: ${({ theme }) => theme.colors.semantic.info};
  }

  .rdp:not([dir="rtl"]) .rdp-day_range_start:not(.rdp-day_range_end),
  .rdp:not([dir="rtl"]) .rdp-day_range_end:not(.rdp-day_range_start) {
    border-radius: 50%;
  }
`;

import React from "react";
import { useTheme } from "styled-components";
import { Trash } from "../constants";
import { RowBetween } from "./styles/Layout.styled";
import { QuickTaskWrapper } from "./styles/QuickTask.styled";
import { Spacer } from "./styles/Spacer.styled";
import { Text12, Text14, Text16 } from "./styles/Typography.styled";

export default function QuickTask({ days, proxy }) {
  const theme = useTheme();
  return (
    <QuickTaskWrapper>
      <RowBetween>
        <Text14 color={theme.colors.primary.black}>{days} days ago</Text14>
        <div className="delete">
          <Trash />
        </div>
      </RowBetween>
      <Spacer bottom={"0.25rem"} />
      <Text16 color={theme.colors.bnw.black1} weight={700}>
        {proxy}
      </Text16>
      <Spacer bottom={"0.5rem"} />
      <Text12
        className="downloadBtn"
        onClick={() => window.alert("Download clicked")}
        weight={700}
        color={theme.colors.semantic.info}
      >
        Download
      </Text12>
    </QuickTaskWrapper>
  );
}

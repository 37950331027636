import React from "react";
import { Link } from "react-router-dom";
import { useTheme } from "styled-components";
import OrderSummary from "../components/OrderSummary";
import PackageDetails from "../components/PackageDetails";
import { RowCenter } from "../components/styles/Layout.styled";
import { Spacer } from "../components/styles/Spacer.styled";
import { Text14 } from "../components/styles/Typography.styled";
import { BackSvg } from "../constants";
import { EqualColumns } from "./styles/EqualColumns.styled";
import { ShopDetailWrapper } from "./styles/ShopDetail.styled";

export default function ShopDetail() {
  // const params = new URLSearchParams(window.location.search);
  // const id = params.get("id");

  const theme = useTheme();

  return (
    <ShopDetailWrapper>
      <Link to="/shop">
        <RowCenter className="link">
          <BackSvg />
          <Spacer left="1rem" />
          <Text14 color={theme.colors.bnw.black3}>Back to Shop</Text14>
        </RowCenter>
      </Link>
      <Spacer bottom="2.5rem" />
      <EqualColumns columns={2}>
        <PackageDetails />
        <OrderSummary />
      </EqualColumns>
    </ShopDetailWrapper>
  );
}

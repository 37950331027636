import React from "react";
import styled from "styled-components";
import { Download, RedTrash } from "../constants";
import { RowCenter } from "./styles/Layout.styled";
import { Text12 } from "./styles/Typography.styled";

const MenuOptionsWrapper = styled.div`
  background: #ffffff;
  box-shadow: 0rem 0.6875rem 1.5rem rgba(52, 113, 202, 0.310971);
  border-radius: 0.5rem;
  padding: 0.5rem;

  svg {
    width: 1rem;
    height: 1rem;
    margin-right: 0.625rem;
  }

  .value {
    min-width: 5.25rem;
    padding: 0.5rem;
    cursor: pointer;

    &:hover {
      background: #f4f6fc;
      border-radius: 0.5rem;
    }
  }
`;

export default function ProxyMenuOptions() {
  return (
    <MenuOptionsWrapper>
      <div className="value">
        <RowCenter>
          <Download />
          <Text12 weight={500}>Save</Text12>
        </RowCenter>
      </div>
      <div className="value">
        <RowCenter>
          <RedTrash />
          <Text12 weight={500} color={"#FF503D"}>
            Clear
          </Text12>
        </RowCenter>
      </div>
    </MenuOptionsWrapper>
  );
}

import React from "react";
import { useRecoilState } from "recoil";
import { useTheme } from "styled-components";
import { InputWrapper } from "./styles/Input.styled";
import { Text14 } from "./styles/Typography.styled";

export default function Input({ label, state, ...props }) {
  const [value, setValue] = useRecoilState(state);
  const theme = useTheme();
  return (
    <InputWrapper>
      {label ? (
        <Text14 weight={500} color={theme.colors.bnw.black4}>
          {label}
        </Text14>
      ) : (
        <></>
      )}

      <input
        value={value}
        onChange={(e) => setValue(e.target.value)}
        {...props}
      />
    </InputWrapper>
  );
}

import styled from "styled-components";

export const SelectWrapper = styled.div`
  background: ${({ lg }) => (!lg ? "#ffffff" : "#f4faff")};
  box-shadow: ${({ border }) =>
    border ? " 0rem 0rem 0.1875rem rgba(63, 116, 228, 0.1)" : ""};
  position: relative;
  color: #8c93a2;
  width: 100%;
  border: ${({ lg }) => (!lg ? "none" : "0.0625rem solid #e6edf2")};
  border-radius: 0.5rem;
  font-size: 1rem;
  line-height: ${({ lg }) => (!lg ? "1rem" : "1.5rem")};

  .select {
    padding: ${({ lg }) => (!lg ? "0.25rem 0.5rem" : "1rem")};
  }

  .placeholder {
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.5rem;
    color: #606e79;
  }

  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10000;
  }

  svg {
    margin-left: 0.25rem;
  }

  .values {
    position: absolute;
    width: 100%;
    left: 0;
    top: 100%;
    margin-top: 0.25rem;
    z-index: 10001;
  }

  .valuesWrapper {
    padding: 0.25rem 0.5rem;
    border-radius: 0.375rem;
    width: 100%;
    max-height: 18.75rem;
    overflow-y: auto;
    background: #fff;
    box-shadow: 0rem 0.625rem 1.25rem rgba(227, 230, 236, 0.5);
  }

  .value {
    margin: 0.125rem 0;
    cursor: pointer;
    padding: ${({ lg }) => (!lg ? "0.5rem" : "1rem")};
    border-radius: 0.25rem;
    color: #3a3f4a;

    &:hover {
      background: #f4f6fc;
    }

    &.active {
      background: #efefef;
      font-weight: 500;
    }
  }
`;

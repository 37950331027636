export const theme = {
  colors: {
    primary: {
      aqua: "#13CEFF",
      blue: "#165CEE",
      darkBlue: "#0D3990",
    },
    border: {
      style1: "#E6EDF2",
      style2: "#E6EEFD",
      style3: "#F1F1F5",
    },
    bg: {
      green: "rgba(0, 201, 184, 0.1)",
      style1: "#F4FAFF",
      style2: "#F4F6FC",
      style3: "#E8EBF4",
    },
    bnw: {
      gray1: "#93B0C8",
      gray2: "#DEE1E3",
      black1: "#031828",
      black2: "#374151",
      black3: "#606E79",
      black4: "#8C93A2",
    },
    semantic: {
      success: "#00C9B8",
      warning: "#FF974A",
      danger: "#FF503D",
      info: "#1B61EE",
    },
    grey: "#A0A5BA",
    white: "#FFFFFF",
  },
};

import React from "react";
import { useTheme } from "styled-components";
import { AddSvg, CheckSvg } from "../constants";
import CustomProgress from "./CustomProgress";
import FadeIn from "./FadeIn";
import FeatsPopup from "./FeatsPopup";
import { RowBetween, RowCenter } from "./styles/Layout.styled";
import { LevelsWrapper } from "./styles/Levels.styled";
import { Spacer } from "./styles/Spacer.styled";
import { Text14, Text16 } from "./styles/Typography.styled";

export default function Level({
  levelName,
  currentPoints,
  totalPoints,
  feats,
  subText,
}) {
  const [popupOpen, setPopupOpen] = React.useState(false);
  const theme = useTheme();

  const color =
    currentPoints === totalPoints
      ? theme.colors.semantic.success
      : theme.colors.semantic.info;

  return (
    <LevelsWrapper>
      <RowBetween>
        <Text14 weight={500} color={theme.colors.bnw.black3}>
          {levelName}
        </Text14>
        {currentPoints === totalPoints ? (
          <div className="icon">
            <CheckSvg checked />
          </div>
        ) : null}
      </RowBetween>
      <Spacer bottom="0.25rem" />
      {currentPoints === totalPoints ? (
        <Text16 weight={700} color={theme.colors.primary.black}>
          Completed
        </Text16>
      ) : (
        <RowCenter>
          <Text16 weight={700} color={theme.colors.primary.black}>
            {currentPoints}
          </Text16>
          <Text16 weight={400} color={theme.colors.bnw.gray1}>
            /{totalPoints}
          </Text16>
        </RowCenter>
      )}
      <Spacer bottom="0.625rem" />
      <CustomProgress
        color={color}
        currentPoints={currentPoints}
        totalPoints={totalPoints}
      />
      <Spacer bottom="0.75rem" />
      <Text14 weight={500} color={theme.colors.bnw.black3}>
        {subText}
      </Text14>
      <Spacer bottom="0.25rem" />
      <RowCenter className="feats" onClick={() => setPopupOpen(!popupOpen)}>
        {popupOpen ? (
          <FadeIn disableVertical>
            <FeatsPopup color={color} feats={feats} level={levelName} />
          </FadeIn>
        ) : null}
        <Text14 weight={500} color={theme.colors.semantic.info}>
          Features
        </Text14>
        <Spacer left="0.25rem" />
        <AddSvg />
      </RowCenter>
    </LevelsWrapper>
  );
}

import React from "react";
import { useTheme } from "styled-components";
import { CheckSvg } from "../constants";
import FadeIn from "./FadeIn";
import { DataVolumeWrapper } from "./styles/DataVolume.styled";
import { RowCenter } from "./styles/Layout.styled";
import { Spacer } from "./styles/Spacer.styled";
import { H1, Text14, Text16 } from "./styles/Typography.styled";

export default function DataVolume({ data }) {
  const [active, setActive] = React.useState(0);

  const theme = useTheme();
  return (
    <DataVolumeWrapper>
      <Text16 weight={700} color={theme.colors.bnw.black3}>
        Data volume
      </Text16>
      <Spacer bottom="1.25rem" />
      <RowCenter>
        {data.map((item, index) => (
          <FadeIn key={index} i={index} data-volume>
            <div
              className={`data-volume ${active === index ? "active" : ""}`}
              onClick={() => setActive(index)}
            >
              <RowCenter className={`check`}>
                <CheckSvg checked={active === index} />
              </RowCenter>
              <H1
                weight={700}
                color={
                  active !== index
                    ? theme.colors.primary.black
                    : theme.colors.white
                }
              >
                {item.toString().padStart(2, "0")}
                <Text14
                  weight={400}
                  color={
                    active !== index
                      ? theme.colors.bnw.black3
                      : "rgba(255,255,255,.5)"
                  }
                >
                  Gigabytes
                </Text14>
              </H1>
            </div>
          </FadeIn>
        ))}
      </RowCenter>
    </DataVolumeWrapper>
  );
}

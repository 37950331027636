import React from "react";
import cardIcon from "../assets/card.png";
import wechatIcon from "../assets/wechat.png";
import alipayIcon from "../assets/alipay.png";
import { RowCenter } from "./styles/Layout.styled";
import { Spacer } from "./styles/Spacer.styled";
import { useRecoilState } from "recoil";
import { paymentMethodState } from "../recoil/general";
import { CheckedBlueSvg, UncheckedSvg } from "../constants";
import styled from "styled-components";

const methods = [
  {
    name: "card",
    img: cardIcon,
  },
  { name: "wechat", img: wechatIcon },
  { name: "alipay", img: alipayIcon },
];

const PaymentMethodsWrapper = styled.div`
  .payment-method {
    cursor: pointer;

    img {
      margin-left: 0.75rem;
    }
  }
`;

export default function PaymentMethods() {
  const [activeMethod, setActiveMethod] = useRecoilState(paymentMethodState);
  return (
    <PaymentMethodsWrapper>
      {methods.map((e, i) => {
        return (
          <React.Fragment key={i}>
            <RowCenter
              className="payment-method"
              onClick={() => setActiveMethod(e.name)}
            >
              {activeMethod === e.name ? <CheckedBlueSvg /> : <UncheckedSvg />}
              <img src={e.img} alt={e.name} />
            </RowCenter>
            <Spacer bottom="1rem" />
          </React.Fragment>
        );
      })}
    </PaymentMethodsWrapper>
  );
}

import React from "react";
import Expiration from "../components/Expiration";
import ProxiesList from "../components/ProxiesList";
import ProxyPlanOrder from "../components/ProxyPlanOrder";
import { Spacer } from "../components/styles/Spacer.styled";
import Switch from "../components/Switch";
import Topbar from "../components/Topbar";
import { proxySwitchState } from "../recoil/tabs";
import { EqualColumns } from "./styles/EqualColumns.styled";
import { StyledPage } from "./styles/PageStyle.styled";

export default function Proxies() {
  return (
    <StyledPage>
      <Topbar pageName={"Proxies"} />
      <Switch values={["Premium", "Basic"]} state={proxySwitchState} />
      <Spacer bottom="2.25rem" />
      <EqualColumns columns={3}>
        <ProxyPlanOrder />
        <Expiration />
        <ProxiesList
          data={new Array(15).fill("mock.speedy.so:9999:Username:Password:1")}
        />
      </EqualColumns>
    </StyledPage>
  );
}

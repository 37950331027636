import React from "react";
import { useTheme } from "styled-components";
import { GridWrapper } from "./styles/PointsHistoryGrid.styled";
import { Text14 } from "./styles/Typography.styled";

export default function PointsHistoryGrid({
  pointsHistory = [
    {
      type: "Earned",
      points: 100,
      addedOn: "26-May-21",
      expiryDate: "2021-01-01",
    },
    {
      type: "Referral Commission",
      points: 100,
      addedOn: "26-May-21",
      expiryDate: "Expired",
    },
    {
      type: "Earned",
      points: 100,
      addedOn: "26-May-21",
      expiryDate: "Never Expire",
    },
    {
      type: "Earned",
      points: 100,
      addedOn: "26-May-21",
      expiryDate: "Never Expire",
    },
    {
      type: "Earned",
      points: 100,
      addedOn: "26-May-21",
      expiryDate: "Never Expire",
    },
    {
      type: "Earned",
      points: 100,
      addedOn: "26-May-21",
      expiryDate: "Never Expire",
    },
  ],
}) {
  const theme = useTheme();

  return (
    <GridWrapper>
      <div className="header">
        <Text14 title="Point type" weight={500} color={theme.colors.bnw.black4}>
          Point type
        </Text14>
        <Text14 title="Points" weight={500} color={theme.colors.bnw.black4}>
          Points
        </Text14>
        <Text14 title="Added on" weight={500} color={theme.colors.bnw.black4}>
          Added on
        </Text14>
        <Text14
          title="Expiry date"
          weight={500}
          color={theme.colors.bnw.black4}
        >
          Expiry date
        </Text14>
      </div>
      <div className="values">
        {pointsHistory.map((item, index) => (
          <div className="row" key={index}>
            <Text14
              title={item.type}
              weight={500}
              color={theme.colors.bnw.black2}
            >
              {item.type}
            </Text14>
            <Text14
              title={`
                ${item.type === "Earned" ? "+" : "-"}${item.points}
              `}
              weight={500}
              color={
                item.type === "Earned"
                  ? theme.colors.semantic.success
                  : theme.colors.semantic.danger
              }
            >
              {item.type === "Earned" ? "+" : "-"}
              {item.points}
            </Text14>
            <Text14
              title={item.addedOn}
              weight={500}
              color={theme.colors.bnw.black2}
            >
              {item.addedOn}
            </Text14>
            <Text14
              title={item.expiryDate}
              weight={500}
              color={
                item.expiryDate === "Expired"
                  ? theme.colors.semantic.danger
                  : item.expiryDate === "Never Expire"
                  ? theme.colors.semantic.success
                  : theme.colors.bnw.black2
              }
            >
              {item.expiryDate}
            </Text14>
          </div>
        ))}
      </div>
    </GridWrapper>
  );
}

import styled from "styled-components";

export const GridWrapper = styled.div`
  .header,
  .row {
    border-radius: 0.5rem;
    display: grid;
    grid-template-columns: 2fr 1fr 1fr 1fr;
    padding: 0.75rem 1rem;
    align-items: center;
  }

  .row {
    transition: all 0.2s ease-in-out;
    margin: 0.25rem 0;

    &:hover {
      background-color: ${({ theme }) => theme.colors.bg.style3};
    }

    & > div {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .header {
    background-color: ${({ theme }) => theme.colors.bg.style2};

    & > div {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .values {
    max-height: 13rem;
    overflow: auto;
  }

  &.invoice-grid {
    .header,
    .row {
      grid-template-columns: 0.75fr 1fr 2fr 0.75fr 1fr 1fr 0.5fr;
    }

    .values {
      max-height: 20rem;
    }
  }

  .action {
    cursor: pointer;
  }
`;

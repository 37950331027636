import React from "react";
import ReactApexChart from "react-apexcharts";
import { useRecoilValue } from "recoil";
import { usageServerState } from "../recoil/select";

export default function StackedColumnChart() {
  const selected = useRecoilValue(usageServerState);

  const colors = {
    "footlocker.de": ["#1B61EE"],
    "footlocker.fr": ["#13CEFF"],
    "Solect.io": ["#FF974A"],
    "loremipsum.de": ["#FFC542"],
    "All Servers": ["#1B61EE", "#13CEFF", "#FF974A", "#FFC542"],
  };

  const series = [
    {
      name: "footlocker.de",
      data: [44, 55, 41, 67, 22, 43, 21, 49],
    },
    {
      name: "footlocker.fr",
      data: [13, 23, 20, 8, 13, 27, 33, 12],
    },
    {
      name: "Solect.io",
      data: [11, 17, 15, 15, 21, 14, 15, 13],
    },
    {
      name: "loremipsum.de",
      data: [21, 7, 25, 13, 22, 8, 10, 10],
    },
  ];

  const options = {
    chart: {
      width: "100%",
      parentHeightOffset: 0,
      fontFamily: "inherit",
      fontWeight: 500,
      color: "red",
      fontSize: "0.75rem",
      type: "bar",
      stacked: true,
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
    },
    grid: {
      padding: {
        left: -1,
      },
    },
    states: {
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: "none",
        },
      },
      hover: {
        filter: {
          type: "lighten",
          value: 0.2,
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          legend: {
            show: false,
          },
        },
      },
    ],
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "50%",
        borderRadius: 4,
      },
    },
    xaxis: {
      type: "category",
      categories: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug"],
      labels: {
        show: true,
        style: {
          colors: "#9CA3AF",
          fontSize: "0.75rem",
          fontFamily: "inherit",
          fontWeight: 500,
        },
        tickPlacement: "on",
      },
    },
    yaxis: {
      labels: {
        offsetX: -10,
        show: true,
        style: {
          colors: "#9CA3AF",
          fontSize: "0.75rem",
          fontFamily: "inherit",
          fontWeight: 500,
        },
      },
    },
    legend: {
      show: false,
    },
    fill: {
      colors: colors[selected],
      opacity: 1,
    },
  };

  return (
    <div id="chart">
      <ReactApexChart
        options={options}
        series={
          selected === "All Servers"
            ? series
            : series.filter((item) => item.name === selected)
        }
        type="bar"
        height={490}
      />
    </div>
  );
}

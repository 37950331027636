import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
    * {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
    }

    body {
        font-family: "Red Hat Display", sans-serif;
        font-weight: 400;
        background: #f4f6fc;
    }

    a {
        text-decoration: none;
        color: inherit;
    }

    button {
        font-family: "Red Hat Display", sans-serif;
        font-weight: 400;
        cursor: pointer;
        border: none;
        outline: none;
    }

    input {
        font-family: "Red Hat Display", sans-serif;
        font-weight: 400;
        outline: none;
        border: none;
    }

    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    /* Firefox */
    input[type="number"] {
        -moz-appearance: textfield;
    }

    ::-webkit-scrollbar {
        width: 0.325em;
    }

    ::-webkit-scrollbar-thumb {
        background-color: rgba(0, 0, 0, 0.2);
        border-radius: 1rem;
    }

    .rdp{
        margin: 0;
    }

    @media (max-width: 1600px) {
    * {
        font-size: 15px;
    }
    }

    @media (max-width: 1400px) {
    * {
        font-size: 14px;
    }
    }

    @media (max-width: 1300px) {
    * {
        font-size: 12px;
    }
    }
`;

export default GlobalStyle;

import React from "react";
import { motion } from "framer-motion";

export default function FadeIn({ children, i, disableVertical = false }) {
  return (
    <motion.div
      initial={{ opacity: 0, y: disableVertical ? 0 : 20 }}
      animate={{ opacity: 1, y: 0 }}
      key={i}
      transition={{ duration: 0.2, delay: i * 0.1 }}
    >
      {children}
    </motion.div>
  );
}

import React from "react";
import SettingComponent from "../components/SettingsComponent";
import Topbar from "../components/Topbar";
import { EqualColumns } from "./styles/EqualColumns.styled";
import { StyledPage } from "./styles/PageStyle.styled";

export default function Settings() {
  return (
    <StyledPage>
      <Topbar pageName={"Settings"} />
      <EqualColumns columns={3}>
        <SettingComponent />
      </EqualColumns>
    </StyledPage>
  );
}

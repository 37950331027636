import styled from "styled-components";

export const UserMenuWrapper = styled.div`
  width: 14rem;
  right: 0;
  top: 100%;
  position: absolute;
  margin-top: 1rem;
  z-index: 100;

  .arrow {
    position: absolute;
    top: -0.5rem;
    width: 1rem;
    height: 1rem;
    background: #ffffff;
    border-radius: 0.1875rem;
    transform: rotate(45deg);
    right: 1rem;
  }

  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10000;
    cursor: auto;
  }

  .content {
    position: relative;
    z-index: 10001;
    background-color: #fff;
    box-shadow: 0rem 0.625rem 1.25rem rgba(227, 230, 236, 0.5);
    border-radius: 0.5rem;
    overflow: hidden;
  }

  .userDetails {
    padding: 0.75rem 1rem;
    border-bottom: 0.0625rem solid #e3e6ec;
    cursor: auto;
    img {
      width: 2.5rem;
      height: 2.5rem;
      border-radius: 50%;
    }
  }

  .link {
    padding: 0.5rem 1rem;
    transition: all 0.3s ease-in-out;

    &:hover {
      background-color: rgba(227, 230, 236, 0.25);
    }
  }

  .sections {
    border-bottom: 0.0625rem solid #e3e6ec;

    svg {
      width: 1.25rem;
    }
  }

  .links {
    border-bottom: 0.0625rem solid #e3e6ec;
  }

  .signOut {
    padding: 0.75rem 1rem;

    &:hover {
      background-color: #ff503d;
      div {
        color: #fff;
      }

      svg path {
        stroke: #fff;
      }
    }
  }
`;

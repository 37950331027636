import React from "react";
import PlanComponent from "../components/PlanComponent";
import { Spacer } from "../components/styles/Spacer.styled";
import Switch from "../components/Switch";
import Topbar from "../components/Topbar";
import { shopSwitchState } from "../recoil/tabs";
import { EqualColumns } from "./styles/EqualColumns.styled";
import { StyledPage } from "./styles/PageStyle.styled";
import { ResiShopData, WorkShopData } from "../constants/shopData";
import { useRecoilValue } from "recoil";
import FadeIn from "../components/FadeIn";

export default function Shop() {
  const shopValue = useRecoilValue(shopSwitchState);

  return (
    <StyledPage>
      <Topbar pageName={"Shop"} />
      <Switch values={["Residential", "Work"]} state={shopSwitchState} />
      <Spacer bottom="1.875rem" />
      <EqualColumns columns={shopValue === "Residential" ? 3 : 4}>
        {shopValue === "Residential"
          ? ResiShopData.map((e, i) => (
              <FadeIn key={i + 2} i={i}>
                <PlanComponent {...e} />
              </FadeIn>
            ))
          : WorkShopData.map((e, i) => (
              <FadeIn key={i} i={i}>
                <PlanComponent {...e} />
              </FadeIn>
            ))}
      </EqualColumns>
    </StyledPage>
  );
}

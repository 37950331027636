import React from "react";
import { TopBarStyled } from "./styles/Topbar.styled";
import User from "./User";

export default function Topbar({ pageName }) {
  return (
    <TopBarStyled>
      <div className="pagename">{pageName}</div>
      <User />
    </TopBarStyled>
  );
}
